/*
|
| Titles
|-----------------------
|
*/  
@mixin generate-titles() {
    @each $prefix-size, $sizes in $title-sizes {
        .title-#{$prefix-size} {
            @each $prop, $val in $default-titles {
                #{$prop}: $val;
            }

            $min: map-get($sizes, 'min');
            $max: map-get($sizes, 'max');

            @if $min == $max {
                font-size: $min;
            } @else {
                font-size: font-size($min, $max);
            }
        }
    }
}