@mixin generate-spaces() {
    @each $space, $val in $spaces {
        @each $label, $props in $space-properties {
            .#{$label}-#{$space} {
                @each $prop in $props {
                    #{$prop}: $val;                    
                }
            }
        }
    }

    @each $breakpoint in list-reverse(map-keys($breakpoints)) {
        @include media-breakpoint-down($breakpoint) {
            $prefix: #{$breakpoint}-down;

            @each $space, $val in $spaces {
                @each $label, $props in $space-properties {
                    .#{$prefix}-#{$label}-#{$space} {
                        @each $prop in $props {
                            #{$prop}: $val;                    
                        }
                    }
                }
            }
        }
    }
        
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $prefix: #{$breakpoint}-up;

            @each $space, $val in $spaces {
                @each $label, $props in $space-properties {
                    .#{$prefix}-#{$label}-#{$space} {
                        @each $prop in $props {
                            #{$prop}: $val;                    
                        }
                    }
                }
            }
        }
    }
}


@mixin generate-spaces-child() {
    @each $space, $val in $spaces {
        @each $label, $props in $space-properties {
            .#{$label}-#{$space}-child {
                & > * {
                    @each $prop in $props {
                        #{$prop}: $val !important;
                    }
                }
            }
            
            .#{$label}-#{$space}-child-not-last {
                & > *:not(:last-child) {
                    @each $prop in $props {
                        #{$prop}: $val !important;
                    }
                }
            }
        }
    }

    @each $breakpoint in list-reverse(map-keys($breakpoints)) {
        @include media-breakpoint-down($breakpoint) {
            $prefix: #{$breakpoint}-down;

            @each $space, $val in $spaces {
                @each $label, $props in $space-properties {
                    .#{$prefix}-#{$label}-#{$space}-child {
                        & > * {
                            @each $prop in $props {
                                #{$prop}: $val !important;
                            }
                        }
                    }
                    
                    .#{$prefix}-#{$label}-#{$space}-child-not-last {
                        & > *:not(:last-child) {
                            @each $prop in $props {
                                #{$prop}: $val !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $prefix: #{$breakpoint}-up;

            @each $space, $val in $spaces {
                @each $label, $props in $space-properties {
                    .#{$prefix}-#{$label}-#{$space}-child {
                        & > * {
                            @each $prop in $props {
                                #{$prop}: $val !important;
                            }
                        }
                    }
                    
                    .#{$prefix}-#{$label}-#{$space}-child-not-last {
                        & > *:not(:last-child) {
                            @each $prop in $props {
                                #{$prop}: $val !important;
                            }
                        }
                    }
                }
            }
        }
    }
}