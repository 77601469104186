
.custom-select {

    position: relative;

    // display: inline-block;
    display: grid; //To be full width of container (= prevent firefox cutted txt of selected item)
    border: 1px solid $black;
    background-color: $black;

    &:after {
        content: '';

        position: absolute;
        top: 0;
        right: 16px;
        bottom: 0;
        // z-index: 2;

        margin: auto;

        width: 5px;
        height: 5px;

        border: {
            right: 1px solid $white;
            bottom: 1px solid $white;
        }

        transform: rotate(45deg);
    }

    &-item {
        position: relative;
        z-index: 2;

        width: 100%;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        padding: 16px 40px;

        font-family: $font-family-custom;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 3px;
        text-align: center;
        text-align-last: center;
        text-transform: uppercase;

        transition: all .4s;

        cursor: pointer;

        // border: 1px solid $black;
        // background-color: $black;
        color: $white;

        option{

            color: $black;

        }
    }

}