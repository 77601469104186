.testimonials {
    position: relative;

    overflow: hidden;

    &:after {
        content: '';

        position: absolute;
        top: 0;
        left: -10px;
        bottom: 0;
        z-index: 1;

        background-color: $white;

        width: calc((100% - #{map-get($containers, xl)}) / 2);
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-title {
        font-family: $font-family-custom;
        font-size: 18px;
        text-transform: uppercase;
    }

    &-slider {
        overflow: visible;
    }

    &-pagination {
        position: relative;

        display: flex;
        align-items: center;

        &-cursor {
            position: absolute;
            top: 0;
            left: 14px;
            bottom: 0;

            width: 32px;
            height: 32px;

            border: 1px solid $black;
            border-radius: 50%;

            transform: translateX(-14px);
        }

        &-item {
            min-width: 4px;
            height: 4px;

            margin: 14px;

            background-color: $black;
            border-radius: 50%;
        }
    }

    @include media-breakpoint-down(lg) {
        &:after {
            display: none;
        }
    }
}