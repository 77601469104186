#modal-matterport{

    $btn-size: 50px;

    background-color: transparent;
    box-shadow: inherit;

    .iziModal-content{

        position: relative;
        padding-top: $btn-size !important;

    }

    .close-btn-modal{

        position: absolute;
        top: 0;
        right: 0;
        width: $btn-size;
        height: $btn-size;
        background-color: $black;
        cursor: pointer;

        &::before,
        &::after{

            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1px;
            background-color: $white;

            
        }

        &::before{

            transform: translate3d(-50%, -50%, 0) rotate(45deg);

        }

        &::after{

            transform: translate3d(-50%, -50%, 0) rotate(-45deg);

        }

    }

    iframe{

        display: block;
        aspect-ratio: 16/9;
        width: 100%;
        height: auto;

    }

}