/*
|
| Btn
|---------
*/
.btn{
    $btn: &;

    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 16px 40px;

    font-family: $font-family-custom;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;

    cursor: pointer;

    &-overlay {
        position: absolute;
        inset: 0;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        padding: 16px;

        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);

        transition: clip-path .4s;
    }

    &:hover {
        #{$btn}-overlay {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }

    &.d-b {
        display: block;
    }

    &.btn-wtw{

        border: 1px solid $white;
        background-color: transparent;
        color: $white;

        #{$btn}-overlay {
            background-color: white;
            color: $black;
        }

    }

    &.btn-wwb{

        border: 1px solid $white;
        background-color: $white;
        color: $black;

        #{$btn}-overlay {
            background-color: $black;
            color: $white;
        }

    }

    &.btn-wbw{

        border: 1px solid $white;
        background-color: $black;
        color: $white;

        #{$btn}-overlay {
            background-color: $white;
            color: $black;
        }

    }

    &.btn-bwb{

        border: 1px solid $black;
        background-color: $white;
        color: $black;

        #{$btn}-overlay {
            background-color: $black;
            color: $white;
        }

    }

    &.btn-bbw{

        border: 1px solid $black;
        background-color: $black;
        color: $white;

        #{$btn}-overlay {
            background-color: $white;
            color: $black;
        }

    }

    &.btn-btb{

        border: 1px solid $black;
        background-color: transparent;
        color: $black;

        #{$btn}-overlay {
            background-color: $black;
            color: $white;
        }

    }

    &.btn-big{

        padding: 25px 66px;

    }

}