.text_image_cta {
    position: relative;

    &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        aspect-ratio: 1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &--right {
            left: 0;
            right: auto;
        }
    }

    @include media-breakpoint-down(lg) {
        &-image {
            width: 45%;
            aspect-ratio: auto;
        }
    }

    @include media-breakpoint-down(md) {
        &-image {
            position: static;

            width: 100%;
            aspect-ratio: 16 / 9;
        }
    }
}