/*
|
| Link
|---------
*/
.link{

    $line-size: 32px;
    $line-space: 16px;

    position: relative;
    font-family: $font-family-custom;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover {
        &.link-line {
            &:after {
                left: calc(#{$line-size} - 6px);

                opacity: 1;
            }
        }
    }

    &.link-line{

        padding-left: calc($line-size + $line-space);

        &::before,
        &::after{

            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            bottom: 0;
            margin: auto;
            
        }

        &::before{

            width: $line-size;
            height: 0;

            border: {
                bottom: 1px solid;
            }

        }

        &:after {

            width: 7px;
            height: 7px;

            border: {
                top: 1px solid;
                right: 1px solid;
            }

            opacity: 0;
            transform: rotate(45deg);
            transition: left .4s, opacity .4s;
        }

    }

    &.link-white{

        color: $white;

    }
}