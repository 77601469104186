/*
|--------------------
|       Teams
|--------------------
*/
.flexible-team{

    --pb: 80px;

    overflow: hidden;
    padding-block: var(--pb);

    @include media-breakpoint-down(md){

        --pb: 60px;

    }

    @include media-breakpoint-down(sm){

        --pb: 40px;

    }

    .row-controls{

        margin-top: 40px;

        & > div{

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 30px 0;

            @include media-breakpoint-down(sm){

                align-items: flex-start;
            }

        }

    }

    .swiper{

        overflow: visible;

        &::before{

            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            background-color: $beige;

        }

    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next{

        position: relative;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;
        margin: 0;

    }

    .swiper-pagination{

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1 1 calc(100% - 80px);
        gap: 32px 0;

    }

    .swiper-buttons{
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 80px;

    }

    .swiper-button-prev,
    .swiper-button-next{

    }

    &.team-homepage{

        position: relative;
        z-index: 0;

        &::before{

            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            right: 0;
            height: 65%;
            background-color: $black;

        }

        .row-slider{

            margin-top: 100px;

        }

        .swiper{
        
            &::before{

                content: none;

            }

        }

    }

}