.text_image_2 {
    --paddingTop: 380px;

    position: relative;

    &-header {
        position: relative;

        padding: {
            top: var(--paddingTop)
        }
    }

    &-controls {
        position: absolute;
        left: 0;
        bottom: 100%;

        margin: {
            bottom: 40px;
        }
    }

    &-title {
        position: relative;
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: calc(55% - 32px);

        &--left {
            left: auto;
            right: 0;
        }

        &-item {
            position: absolute;
            inset: 0;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    @include media-breakpoint-down(md) {
        --paddingTop: 0;

        &-controls {
            position: static;
            margin: {
                bottom: 24px;
            }
        }

        &-image {
            position: relative;

            width: 100%;

            aspect-ratio: 16 / 9;
        }
    }
}