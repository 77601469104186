.card-benefit{

    display: none;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 50% 50%;
    gap: 32px;
    margin: 50px 0;

    @include media-breakpoint-down(md){

        grid-template-columns: 1fr;

    }

    .content{

        display: flex;
        flex-direction: column;
        gap: 24px;

        .infos{

            display: flex;
            flex-wrap: wrap;
            gap: 16px 32px;

            .info-item{

                --picto-size: 14px;
                --picto-space: 10px;

                position: relative;
                padding-left: calc(var(--picto-size) + var(--picto-space));

                &::before{
    
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate3d(0, calc(-50% - 1px), 0);
                    width: var(--picto-size);
                    height: var(--picto-size);
                    background-color: $black;
                    mask:
                        center / auto
                        no-repeat
                    ;
                    -webkit-mask:
                        center / auto
                        no-repeat
                    ;
                    
                }

                &.duration{

                    &::before{

                        mask-image: url('../img/icon/clock.svg');
                        -webkit-mask-image: url('../img/icon/clock.svg');

                        
                    }
        
                }
        
                &.planning{

                    &::before{

                        mask-image: url('../img/icon/calendar.svg');
                        -webkit-mask-image: url('../img/icon/calendar.svg');

                        
                    }
        
                }

            }

        }

        .cta-benefit{

            align-self: flex-start;

        }

    }

    .image-container{

        @include media-breakpoint-down(md){

            height: 300px;

        }

        @include media-breakpoint-down(sm){

            height: 150px;

        }

    }

    &.active{

        display: grid;

    }

}