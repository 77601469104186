body {
    &.menu-active {
        overflow: hidden;
    }
}

/*
|
| Header
|---------
*/
%link-menu {

    color: $white;
    font-size: 14px;
    font-weight: 300;

}

#header{

    --py: 15px;

    width: 100%;
    position: fixed;
    z-index: 10;
    padding-block: var(--py);
    transform: translate3d(0, 0, 0);
    transition: all .3s $easeSmooth;

    @include media-breakpoint-down(lg){

        --py: 25px;

    }

    @include media-breakpoint-down(md){

        --py: 15px;

    }

    .header-container{

        display: grid;
        grid-template-columns: 40% 20% 40%;

        @include media-breakpoint-down(sm) {

            grid-template-columns: 20% auto 20%;
            gap: .5rem;

        }

        .main-nav{

            display: flex;
            justify-content: flex-start;
            align-items: center;

        }

        .logo{

            display: flex;
            justify-content: center;
            align-items: center;

            .item-logo{

                display: flex;
                justify-content: center;
                align-items: center;

                min-width: 250px;

                @include media-breakpoint-down(md) {
                    min-width: 200px;
                }

            }

            img,
            svg{

                width: 100%;
                height: auto;

            }

        }

        .other{

            display: flex;
            justify-content: end;
            align-items: center;

            @include media-breakpoint-down(sm) {

                display: none;

            }

            .cta-header{

                margin-left: 35px;

                @include media-breakpoint-down(lg) {
                    display: none;
                }

            }

        }

        .item-nav {

            display: none;

            .item-menu{

                display: flex;
                gap: 12px;

                li{

                    .item-link {

                        @extend %link-menu;

                    }

                }

            }

            // @include media-breakpoint-up(xl) {

            //     display: flex;
            //     align-items: center;

            //     // .item-menu {

            //     //     margin-right: 25px;

            //     // }

            // }

        }

    }

    .lang-container{

        @include media-breakpoint-down(lg){

            display: none;

        }

        .flag{

            // transform: translate3d(0, -2px, 0);

        }

    }

    .burger{

        margin-right: 28px;

        .btn-menu{

            --py: 12px;

            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: var(--py) 0;
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: background .3s $easeSmooth;


            @include media-breakpoint-down(lg){

                --py: 9px;

            }

            @include media-breakpoint-down(md){

                --py: 4px;

            }

            & > .item-burger {

                display: flex;
                flex-direction: column;
                gap: 5px;
                padding: 17.5px;
                width: 68px;
                background-color: $white;

                & > span {

                    display: block;
                    width: 36px;
                    height: 1px;
                    background-color: $black;

                    transition: background .3s $easeSmooth;

                    // &:nth-child(2) {

                    //     margin: 4px 0;

                    // }

                }

            }

            @include media-breakpoint-down(md) {
                .item-burger {
                    width: 60px;

                    padding: 16px 12px;
                }
            }

        }

    }

    // States

    &[data-header*="change"] {

        background-color: $black;

        .header-container{

            .item-nav {

                display: none;

                @include media-breakpoint-up(xl) {

                    display: flex;

                }

            }

        }

        // .burger{

        //     .btn-menu{

        //         background-color: $white;

        //         & > .item-burger {

        //                 & > span {

        //                     background-color: $black;

        //                 }

        //         }

        //     }

        //     .burger-txt{

        //         display: none;

        //     }

        // }

    }

    &[data-header*="hide"] {

        transform: translate3d(0, -100%, 0);

    }

}

/*
|
| Menu
|-------
*/
#mobile-menu {
    min-width: 528px;
    height: 100vh;

    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    padding: var(--side-margin);

    background-color: $black;

    color: $white;
    font-family: $font-family-custom;
    text-transform: uppercase;

    overflow: hidden;

    transform: translateX(-100%);

    .mobile-menu-main {

        padding-right: 10px;
        height: 90%;

        overflow-y: auto;

        // /* Hide scrollbar for Chrome, Safari and Opera */
        // &::-webkit-scrollbar {
        //     display: none;
        // }

        // /* Hide scrollbar for IE, Edge and Firefox */
        // -ms-overflow-style: none;  /* IE and Edge */
        // scrollbar-width: none;  /* Firefox */

        /* width */
        &::-webkit-scrollbar {
            width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $white;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $dark-grey;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $light-grey;
        }

        ul{

            li{

                opacity: var(--li-opacity, 1);
                transition: opacity .3s $easeSmooth;

                &:hover{

                    --li-opacity: 1;

                }

            }

            &:hover{

                --li-opacity: 0.5;

            }

        }

    }

    .mobile_menu_actions{

        display: flex;
        justify-content: space-between;
        align-items: center;

        .lang-container{

            @include media-breakpoint-up(lg){

                display: none;

            }

        }

    }

    .mobile_menu_close {
        display: flex;
        align-items: center;
        gap: 24px;

        font-size: .75rem;
        font-weight: 700;
        letter-spacing: 3px;

        cursor: pointer;

        &-icon {
            position: relative;

            min-width: 32px;
            height: 32px;

            border: 1px solid;
            border-radius: 50%;

            &:before, &:after {
                content: '';

                position: absolute;
                inset: 0;

                margin: auto;

                width: 12px;
                height: 1px;

                background-color: $white;

                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    .burger_main_nav {
        margin: 45px 0 48px;

        &-link {

            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 2rem;
            line-height: 2.4rem;
        }

        li {
            &:not(:last-child) {
                // margin-bottom: 16px;
                margin-bottom: 10px;
            }

            &.menu-item-has-children {
                .burger_main_nav-link {
                    &:after {

                        content: '';

                        // position: absolute;
                        // top: 0;
                        // right: 5px;
                        // bottom: 0;

                        // margin: auto;

                        width: 12px;
                        height: 12px;

                        border: {
                            top: 1px solid;
                            right: 1px solid;
                        }

                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .burger_secondary_nav {
        &-link {
            position: relative;

            display: inline-block;

            font-size: 1.125rem;
            line-height: 1.125rem;

            &[target="_blank"] {
                padding: {
                    right: 24px;
                }

                &:before {
                    content: '';

                    position: absolute;
                    top: 8px;
                    right: -1px;

                    width: 11px;
                    height: 1px;

                    background-color: $white;

                    transform: rotate(-45deg);
                }

                &:after {
                    content: '';

                    position: absolute;
                    top: 4px;
                    right: 0;

                    width: 9px;

                    aspect-ratio: 1 / 1;

                    border: {
                        top: 1px solid;
                        right: 1px solid;
                    }
                }
            }
        }

        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .burger_drop {
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;

        width: 100%;

        padding: var(--side-margin);

        background-color: $black;

        &-back {
            display: flex;
            align-items: center;
            gap: 24px;

            font-size: .75rem;
            font-weight: 700;
            letter-spacing: 3px;

            cursor: pointer;

            &-icon {

                position: relative;
                border: 1px solid;
                border-radius: 50%;
                min-width: 32px;
                height: 32px;

                &:before, &:after {

                    content: '';
                    position: absolute;
                    inset: 0;
                    margin: auto;
                    width: 12px;
                    height: 1px;
                    background-color: $white;
                    transform: var(--pseudo-transform);

                }

                &::before{

                    --pseudo-transform: translate3d(-1px, 4px, 0px) rotate(45deg);

                }

                &:after {

                    --pseudo-transform: translate3d(-1px, -4px, 0px) rotate(-45deg);
                }

            }
        }

        &-item {
            display: none;
        }

        &-title {
            display: inline-block;

            margin: 40px 0;

            font-size: 2rem;
            line-height: 2rem;

            &[href] {
                position: relative;

                padding: {
                    right: 36px;
                }

                &:after {
                    content: '';

                    position: absolute;
                    top: 2px;
                    right: 5px;
                    bottom: 0;

                    margin: auto;

                    width: 12px;
                    height: 12px;

                    border: {
                        top: 1px solid;
                        right: 1px solid;
                    }

                    transform: rotate(45deg);
                }
            }
        }

        &-list {
            font-size: 1.125rem;
            line-height: 1.125rem;

            a {
                display: block;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        min-width: auto;
        width: 100%;
    }
}