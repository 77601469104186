/*
|
| TEXT ALIGN
|-----------------------
|
*/
@mixin generate-text-alignments() {
    @each $label, $val in $align-types {
        @include generate-screen-class-from-map('', 'ta', $label, (text-align: $val));
    }

    @each $breakpoint in list-reverse(map-keys($breakpoints)) {
        @include media-breakpoint-down($breakpoint) {
            $prefix: #{$breakpoint}-down;

            @each $label, $val in $align-types {
                @include generate-screen-class-from-map($prefix, 'ta', $label, (text-align: $val));
            }
        }
    }
     
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $prefix: #{$breakpoint}-up;

            @each $label, $val in $align-types {
                @include generate-screen-class-from-map($prefix, 'ta', $label, (text-align: $val));
            }
        }
    }
}

/*
|
| FONT-SIZES
|----------------
|
*/
@mixin generate-font-sizes() {
    @each $prefix, $size in $font-sizes {
        $current: map-get($size, 'default');

        .fs-#{$prefix}{
            font-size: $current !important;
      
            @each $screen, $val in map-get($size, 'screens') {
                @if $val != $current {
                    @include media-breakpoint-down(#{$screen}) {
                        font-size: $val !important;
                    }
                }

                $current: $val;
            }
        }
    }
}