/*
|-------------------------------
| Margins & Paddings classes creation
|-------------------------------
| Variables used to generate padding & margin classes
| - Generated classes: .m{direction}-{size} & .p{direction}-{size}
| - Exemple: .mt-md & .pt-md
|
*/
$spaces: (
    '0':   0,
    'xs':  $xs,
    'sm':  $sm,
    'md':  $md,
    'lg':  $lg,
    'xl':  $xl,
    'xxl': $xxl,
    'fluid': var(--side-margin)
);

$space-properties: (
    'm':  'margin',
    'mt': 'margin-top',
    'mb': 'margin-bottom',
    'my': 'margin-block',
    'ml': 'margin-left',
    'mr': 'margin-right',
    'mx': 'margin-inline',
    'p' : 'padding',
    'pt': 'padding-top',
    'pb': 'padding-bottom',
    'py': 'padding-block',
    'pl': 'padding-left',
    'pr': 'padding-right',
    'px': 'padding-inline'
);