/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/


/*
|
| DECLARATION
|----------------
|
*/
$black:           #1C1C1C;
$very-dark-grey:  #141414;
$dark-grey:       #424242;
$grey:            #999;
$light-grey:      #BBBBBB;
$very-light-grey: #E5EDE9;
$white:           #FFF;
$white-opacity:   rgba(255, 255, 255, 0.8);
$black-opacity:   rgba(0, 0, 0, 0.6);

$beige:           #FFFDF6;

$red: #e57171;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color:               $grey;
$text-color:                 $very-dark-grey;
$default-background-overlay: $black-opacity;