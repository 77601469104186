/*
|--------------------
|     SHOWROOMS
|      (Home)
|--------------------
*/
.section-showrooms{

    --pb: 160px;
    --mb: 80px;

    padding-block: var(--pb);
    margin-bottom: var(--mb);

    @include media-breakpoint-down(lg){

        --pb: 80px;
        --mb: 0;

    }

    .showrooms-list{

        margin-top: 40px;

        & > *{

            display: flex;
            flex-wrap: wrap;
            gap: 20px 15px;

        }

        .showroom-item{

            cursor: pointer;

            h3{

                opacity: .2;
                transition: opacity .3s $easeSmooth;

            }

            &.active{

                h3{

                    opacity: 1;

                }

            }

        }

    }

    .showrooms-details{

        margin-top: 85px;

        .showroom-infos{

            display: none;
            opacity: 0;
            visibility: hidden;

            &.active{

                display: block;
                opacity: 1;
                visibility: visible;

            }

            .address{

            }

            .phone{

                font-family: $font-family-default !important;
                font-weight: 400 !important;

            }

            .link{

            }

        }

        .showroom-img{

            display: none;
            opacity: 0;
            visibility: hidden;

            .showroom-img-container{

                overflow-y: clip;
                
                position: absolute;
                top: 100%;
                transform: translate3d(0, calc(-100% + (var(--pb) + var(--mb))), 0);
                max-width: 600px;

                @include media-breakpoint-down(lg){

                    position: relative;
                    top: inherit;
                    transform: inherit;
                    overflow: hidden;
                    margin: 0 auto;

                }
                
                img{

                    width: 120%;
                    height: 120%;

                    @include media-breakpoint-down(lg){

                        width: 100%;
                        height: 100%;

                    }

                }

            }

            &.active{

                display: block;
                opacity: 1;
                visibility: visible;

            }

        }

    }

}