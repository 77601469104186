/*
|--------------------
|       Contact
|--------------------
*/

#page-contact{

    .banner-contact {
        min-height: auto;

        margin: {
            bottom: -160px;
        }

        > .container {
            padding: {
                bottom: 80px;
            }
        }
    }

    .double_cta{

        display: block;

        &.hide{

            display: none;
        }

    }

    .text_image_cta{

        display: block;
        margin-bottom: 60px;

        @include media-breakpoint-down(md){

            margin-bottom: 0;
            padding-bottom: 80px;

        }

        @include media-breakpoint-down(sm){

            margin: 0;
            padding: 40px 0;

        }

        &.hide{

            display: none;
        }

    }

    .section-form{

        display: none;
        position: relative;
        z-index: 2;
        margin-top: 80px;

        .row-top{

            .content{

                display: grid;
                grid-template-columns: 1fr;
                gap: 25px;

                .left{

                    position: absolute;

                    @include media-breakpoint-down(lg){

                        position: relative;
                        justify-content: center;

                    }

                }

            }

        }

        .back-btn{

            cursor: pointer;

        }

        .breadcrumb{

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 7px 14px;

            .breadcrumb-item{

                --pictoWidth: 8px;
                --pictoHeight: 4px;
                --pictoSpace: 14px;

                opacity: 0.5;

                &:not(.first){

                    display: none;
                    position: relative;
                    padding-left: calc(var(--pictoWidth) + var(--pictoSpace));

                    &::before{

                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate3d(0, -50%, 0) rotate(-90deg);
                        width: var(--pictoWidth);
                        height: var(--pictoHeight);
                        background-color: $black;
                        mask:
                            url('../img/icon/chevron.svg')
                            center / 100% auto
                            no-repeat
                        ;
                        -webkit-mask:
                            url('../img/icon/chevron.svg')
                            center / 100% auto
                            no-repeat
                        ;

                    }

                }

                &:not(.blur){

                    opacity: 1;

                }

                &.active{

                    display: inline-block;

                }

            }

        }

        .step{

            display: none;
            gap: 72px;

            .step-infos{

            }

            .step-content{

            }

            .find-showroom{

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 600px;
                gap: 32px;

                @include media-breakpoint-down(lg){

                    grid-template-columns: 1fr;

                }

                .left{

                    display: grid;
                    gap: 32px;

                }

                .search{

                    --pictoWidth: 12px;
                    --pictoSpace: 18px;

                    position: relative;
                    padding-left: calc(var(--pictoWidth) + var(--pictoSpace));
                    border-bottom: 1px solid $black;

                    &::after{

                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                        background-color: $black;

                    }

                    &-icon{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;

                        width: calc(var(--pictoWidth) + var(--pictoSpace));
                        height: 100%;

                        cursor: pointer;

                        &:before {
                            content: '';

                            position: absolute;
                            top: 50%;
                            left: 0;

                            transform: translate3d(0, -50%, 0);

                            background-color: $black;

                            width: var(--pictoWidth);
                            height: var(--pictoWidth);

                            mask:
                                url('../img/icon/magnifying-glass.svg')
                                center / 100% auto
                                no-repeat
                            ;
                            -webkit-mask:
                                url('../img/icon/magnifying-glass.svg')
                                center / 100% auto
                                no-repeat
                            ;
                        }

                    }

                    &:after{

                        left: calc(var(--pictoWidth) + var(--pictoSpace));
                        width: 1px;
                        height: 16px;

                    }

                    input{

                        appearance: none;
                        width: 100%;
                        padding: 12px 16px;
                        line-height: 22px;

                    }

                }

                .list{

                    display: flex;
                    gap: 32px;
                    flex-direction: column;

                    overflow-y: auto;

                }

                #map-showrooms{

                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: $black;

                    @include media-breakpoint-down(lg){

                        aspect-ratio: 1 / 1;

                    }

                }

            }

            .ctas-rdv{

                max-width: 75%;
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 32px;

                @include media-breakpoint-down(lg){

                    max-width: inherit;

                }

                @include media-breakpoint-down(md){

                    grid-template-columns: 1fr;

                }

            }

            .select-container{

                padding-bottom: 24px;
                border-bottom: 1px solid $black;

                .selects-showroom{

                    display: none;

                    &.active{

                        // display: block;

                        //To be full width of container (= prevent firefox cutted txt of selected item)
                        display: grid;
                        flex: 1 0 300px;

                    }

                }

                .select-benefit{

                    display: none;

                    @include media-breakpoint-down(sm){

                        width: 100%;

                    }

                    option{

                        &[disabled]{

                            display: none;

                        }

                    }

                    &.active{

                        display: inline-flex;

                    }

                }

            }

            .benefits{

                .sub-benefits{

                    display: none;

                    &.active{

                        display: block;

                    }

                }

            }

            .members{

                .related-members,
                .members-showroom{

                    overflow: hidden;
                    display: none;

                    &.active{

                        display: block;

                    }

                }

                .members-showroom-benefits-type{

                    display: none;

                    &.active{

                        display: block;

                    }

                }

                .members-showroom-benefits{

                    display: none;

                    &.active{

                        display: block;

                    }

                }

            }

            &[data-step-key="2"]{

                gap: 80px;

            }

            &[data-step-key="4"]{

                .step-infos{

                    .cms{

                        max-width: 28%;
                        margin-inline: auto;

                        @include media-breakpoint-down(xl){

                            max-width: 40%;

                        }

                        @include media-breakpoint-down(lg){

                            max-width: 50%;

                        }

                        @include media-breakpoint-down(md){

                            max-width: inherit;

                        }

                    }

                }

                .step-content{

                    overflow: hidden;

                }

            }

            &.active{

                display: grid;

                grid-template-columns: 100%;

            }

        }

        &.active{

            display: block;

        }

    }

    .section-concept-stores{

        display: none;

        &.active{

            display: block;

        }

    }

    .swiper{

        overflow: visible;
        width: 100%;

        &::before{

            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            background-color: $beige;

        }

        .swiper-slide{

            cursor: pointer;

        }

    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next{

        position: relative;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;
        margin: 0;

    }

    .swiper-pagination{

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1 1 calc(100% - 80px);
        gap: 32px 0;

    }

    .swiper-buttons{

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 80px;

    }

    .swiper-button-prev,
    .swiper-button-next{

    }

}
