/*
|--------------------
|   Img + Texte
|--------------------
*/
.flexible-img-txt{

    --pb: 80px;

    padding-block: var(--pb);

    @include media-breakpoint-down(md){

        --pb: 60px;

    }

    @include media-breakpoint-down(sm){

        --pb: 40px;

    }

    .col-img{

        @include media-breakpoint-down(lg){

            overflow-y: clip;

        }

    }

    img{

        position: absolute;
        right: 0;
        width: 120%;
        
        @include media-breakpoint-down(lg){
            
            width: 140%;
            right: inherit;
            left: 50%;
            transform: translate3d(-50%, 0, 0);

        }
        
        @include media-breakpoint-down(sm){
            
            width: 120%;

        }

    }

}