.text_image_3 {
    &-images {
        position: relative;

        &:before {
            content: '';

            position: absolute;
            inset: 240px 0 0;
            z-index: 1;

            background-color: $black;
        }

        &-item {
            position: relative;
            z-index: 2;

            aspect-ratio: 5 / 6;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    @include media-breakpoint-down(lg) {
        &-images {
            &:before {
                top: 160px;
            }
        }
    }
}