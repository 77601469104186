/*
|---------------------------------------------------------------
| TITLES
|---------------------------------------------------------------
| Set all title properties
|
|
*/

/*
|
| DEFAULTS
|----------------
|
*/
$default-titles: (
    font-family: $font-family-custom,
    font-weight: 300,
    line-height: 1.2
);