.slider_text {
    .text_image_2 {
        --paddingTop: 80px;

        &-image {
            position: relative;

            width: 100%;
            aspect-ratio: 5 / 3;

            &-shadow {
                content: '';

                position: absolute;
                top: calc(var(--paddingTop) * -1);
                right: calc(var(--paddingTop) * -1);
                z-index: -1;

                width: 100%;
                height: 100%;

                background-color: $black;
            }
        }
    }

    &-slider {
        position: relative;
    }

    &-pagination {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        flex-direction: column;

        &-cursor {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;

            width: 32px;
            height: 32px;

            border: 1px solid $black;
            border-radius: 50%;
        }

        &-item {
            position: relative;

            width: 32px;
            min-height: 32px;

            cursor: pointer;

            &:before {
                content: '';

                position: absolute;
                inset: 0;

                width: 4px;
                height: 4px;

                margin: auto;

                background-color: $black;
                border-radius: 50%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .text_image_2 {
            --paddingTop: 40px;
        }

        &-pagination {
            position: relative;

            width: 32px;
            height: 32px;

            transform: rotate(-90deg);
            transform-origin: center;

            margin: {
                top: 24px
            }
        }
    }

    @include media-breakpoint-down(md) {
        .text_image_2 {
            --paddingTop: 24px;

            &-header {
                padding: {
                    top: 0
                }
            }

            &-image {
                padding: {
                    top: var(--paddingTop)
                }

                &-shadow {
                    right: 0;

                    width: calc(100% - var(--paddingTop));
                }
            }
        }
    }
}