/*
|-------------------------------
| Grid gutters creation
|-------------------------------
| Variables used to generate grid gutter classes
| - Generated classes: .g-{size}
| - Exemple: .g-md
|
*/
$grids: (
    '12': 12,
    '24': 24
);

$grid-max: map-max($grids);

$gutters: (
    '0':   0px,
    'xs':  $xs,
    'sm':  $sm,
    'md':  $md,
    'lg':  $lg,
    'xl':  $xl,
    'xxl': $xxl
);