/*
|--------------------
|       Formulaire
|--------------------
*/
.page-template-form{

    background-color: $black;

}

%form-grid{

    display: grid;
    grid-template-columns: var(--form-gtc, 100%);
    grid-auto-rows: var(--form-gar, auto);
    grid-template-areas: var(--form-gta, none);
    gap: clamp(1.25rem, 2.7777777778vw, 2.5rem);

    & > * {

        width: 100%;

    }

    @include media-breakpoint-up(lg){

        --form-gtc: repeat(2, 50%);
        --form-gar: min(101px);
        --form-gta:
            "last_name txt"
            "first_name txt"
            "mail txt"
            "phone rgpd"
            "stores captcha"
        ;

        @include media-breakpoint-up(lg){

            grid-column: 1 / -1;
            grid-row: 1 / -1;

        }

    }

}

#page-form{

    .banner-contact {

        min-height: auto;

        margin: {

            bottom: -160px;

        }

        > .container {

            padding: {

                bottom: 80px;

            }

        }

    }

    .section-form{

        position: relative;
        z-index: 1;
        margin-bottom: clamp(3.4375rem, 7.5vw, 6.75rem);

        .container{

            display: grid;
            grid-template-columns: 100%;
            gap: clamp(2.5rem, 6.9444444444vw, 6.25rem);
            padding: clamp(1.75rem, 3.8888888889vw, 3.5rem);
            background-color: $white;

        }

        .form-container{

            // .gform_heading{

            //     display: none;

            // }

            .gform_validation_errors {
                padding: 0;

                font-weight: 300;

                background-color: transparent;
                border: none;
                box-shadow: none;

                .gform_submission_error {
                    font-weight: 300
                }
            }

            form{

                .gform_body{

                    .gform_fields{

                        @extend %form-grid;

                        .spacer{

                            display: none !important;

                        }

                        .gfield{

                            // display: grid;
                            // grid-template-columns: 100%;
                            // align-items: flex-start;

                            display: flex;
                            flex-direction: column;
                            gap: .5rem;

                            .gfield_label{

                                display: none;

                                // margin: 0;
                                // padding: 0;

                                // display: flex;
                                // gap: .5rem;

                                // font-family: $font-family-custom;
                                // text-transform: uppercase;
                                // font-size: 1rem;
                                // font-weight: 300;

                                // transform: translateY(var(--label-translate));
                                // opacity: var(--label-opacity);
                                // transition:
                                //     transform .3s ease-in,
                                //     opacity .3s ease-in
                                // ;


                                // & > .gfield_required{

                                //     & > .gfield_required{

                                //         color: $black !important;

                                //     }

                                // }

                            }

                            .ginput_container{

                                flex-grow: 1;

                                input{

                                    width: 100%;

                                    font-family: $font-family-custom;
                                    font-size: clamp(1.5rem, 3.3333333333vw, 3rem);
                                    font-weight: 300;
                                    text-transform: uppercase;

                                    &::placeholder{

                                        color: $black;
                                        opacity: .7;

                                    }

                                    &[type="text"],
                                    &[type="email"],
                                    &[type="tel"]{

                                        padding: .5rem;
                                        border: none;
                                        border-bottom: 1px solid $black;

                                    }

                                }

                                textarea{

                                    border: 1px solid $black;
                                    margin: 0;
                                    padding: 1.5rem 1rem;
                                    width: 100%;
                                    // height: 100%;

                                    font-family: $font-family-custom;
                                    font-size: clamp(1.5rem, 3.3333333333vw, 3rem);
                                    font-weight: 300;
                                    text-transform: uppercase;

                                    &::placeholder{

                                        color: $black;
                                        opacity: .7;

                                    }

                                }

                                select{

                                    border: none;
                                    border-bottom: 1px solid $black;
                                    font-family: $font-family-custom;
                                    font-size: clamp(1.5rem, 3.3333333333vw, 3rem);
                                    font-weight: 300;
                                    text-transform: uppercase;
                                    opacity: .7;

                                    option{

                                        font-size: 1rem;

                                    }

                                }

                                &.ginput_container_select{

                                    position: relative;
                                    isolation: isolate;
                                    cursor: pointer;

                                    &::after{

                                        --icon-size: 2rem;

                                        content: '';
                                        position: absolute;
                                        z-index: -1;
                                        top: 50%;
                                        right: 0;
                                        transform: translateY(-50%);
                                        width: var(--icon-size);
                                        height: var(--icon-size);
                                        background-color: $black;
                                        mask:
                                            url('../img/icon/arrow-circle.svg')
                                            center / 100% auto
                                            no-repeat
                                        ;
                                        -webkit-mask:
                                            url('../img/icon/arrow-circle.svg')
                                            center / 100% auto
                                            no-repeat
                                        ;

                                    }

                                }

                                .gfield_description.instruction.validation_message {
                                    display: none;
                                }

                            }

                            .gfield_validation_message{

                                margin: 0;
                                padding: 0;

                                background-color: transparent;
                                border: none;

                            }

                            &:not(.focused){

                                --label-translate: 100%;
                                --label-opacity: 0;

                            }

                            &.gfield--width-half{

                                @include media-breakpoint-down(lg){

                                    grid-column: inherit !important;

                                }

                            }

                            &.gfield--type-captcha{

                                .gfield_label{

                                    display: none;

                                }

                            }

                            &.rgpd{

                                .gfield_label {

                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    opacity: 0;
                                    visibility: hidden;

                                }

                                .ginput_container_consent {

                                    position: relative;

                                    input {

                                        position: absolute;
                                        opacity: 0;

                                        &:checked {

                                            &+.gfield_consent_label {

                                                --pseudo-scale: 1;

                                            }

                                        }

                                    }

                                    .gfield_consent_label {

                                        --icon-size: 1rem;

                                        display: block;
                                        position: relative;
                                        padding-left: calc(var(--icon-size) * 2);
                                        color: $black;

                                        &::before,
                                        &::after {

                                            content: '';
                                            position: absolute;
                                            top: var(--pseudo-top, 4px);
                                            left: var(--pseudo-left, 0);
                                            cursor: pointer;

                                        }

                                        &::before {

                                            width: var(--icon-size);
                                            height: var(--icon-size);
                                            border: 2px solid $black;

                                        }

                                        &::after {

                                            --pseudo-top: 8px;
                                            --pseudo-left: 4px;

                                            width: calc(var(--icon-size) / 2);
                                            height: calc(var(--icon-size) / 2);
                                            background-color: $black;
                                            transform: scale(var(--pseudo-scale, 0));
                                            transition: transform .3s ease-in;

                                        }

                                        a {

                                            text-decoration: underline;

                                        }

                                    }

                                }

                            }

                        }

                    }

                    .field{

                        &-last_name{

                            @include media-breakpoint-up(lg){

                                grid-area: last_name;

                            }

                        }

                        &-first_name{

                            @include media-breakpoint-up(lg){

                                grid-area: first_name;

                            }

                        }

                        &-mail{

                            @include media-breakpoint-up(lg){

                                grid-area: mail;

                            }

                        }

                        &-phone{

                            @include media-breakpoint-up(lg){

                                grid-area: phone;

                            }

                        }

                        &-stores{

                            @include media-breakpoint-up(lg){

                                z-index: 2;
                                grid-area: stores;

                            }

                        }

                        &-txt{

                            @include media-breakpoint-up(lg){

                                grid-area: txt;
                                transform: translateY(-28px);

                            }

                        }

                        &-rgpd{

                            @include media-breakpoint-up(lg){

                                grid-area: rgpd;

                            }

                        }

                        &-captcha{

                            @include media-breakpoint-up(lg){

                                grid-area: captcha;

                            }

                        }

                    }


                }

                .gform_footer{

                    // .gform_button{

                    //     @include media-breakpoint-up(lg){

                    //         grid-area: footer;

                    //     }

                    // }

                    @include media-breakpoint-up(lg){

                        display: flex;
                        justify-content: flex-end;
                        // transform: translateY(-100px);

                    }

                }

            }


        }

    }

}
