@mixin generate-grid-rows() {
    .row,
    [class^='row-'],
    [class*=' row-'] {
        --gx: #{$grid-col-gap};
        --gy: #{$grid-row-gap};
    
        --tgx: max(0px, calc((var(--cc) - 1) * var(--gx)));
    
        display: flex;
    
        flex-wrap: wrap;
        gap: var(--gy) var(--gx);
    }

    .row {
        --cc: #{$grid-default};
    }

    @each $name, $val in $grids {
        @if $val != $grid-default {
            .row-#{$name} { --cc: #{$val} }
        }
    }
}


@mixin generate-grid-gutters() {
    @each $label, $val in $gutters {
        @include generate-screen-class-from-map('', 'g', $label, (--gx: $val, --gy: $val));
        
        @include generate-screen-class-from-map('', 'gy', $label, (--gy: $val));
        
        @include generate-screen-class-from-map('', 'gx', $label, (--gx: $val));
    }

    @each $breakpoint in list-reverse(map-keys($breakpoints)) {
        @include media-breakpoint-down($breakpoint) {
            $prefix: #{$breakpoint}-down;

            @each $label, $val in $gutters {
                @include generate-screen-class-from-map($prefix, 'g', $label, (--gx: $val, --gy: $val));
                
                @include generate-screen-class-from-map($prefix, 'gy', $label, (--gy: $val));
                
                @include generate-screen-class-from-map($prefix, 'gx', $label, (--gx: $val));
            }
        }
    }
        
    @each $breakpoint in map-keys($breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $prefix: #{$breakpoint}-up;

            @each $label, $val in $gutters {
                @include generate-screen-class-from-map($prefix, 'g', $label, (--gx: $val, --gy: $val));

                @include generate-screen-class-from-map($prefix, 'gy', $label, (--gy: $val));
                
                @include generate-screen-class-from-map($prefix, 'gx', $label, (--gx: $val ));
            }
        }
    }
}


@mixin generate-grid-columns() {
    .col,
    [class^='col-'],
    [class*=' col-'] {
        --cw: calc((100% - var(--tgx)) / var(--cc) * var(--cs) + (var(--cs) - 1) * var(--gx));

        width: var(--cw, 100%);
        flex: 0 0 var(--cw, 100%);
    }

    [class^='offset-'],
    [class*=' offset-'] {
        margin-left: calc((100% - var(--tgx)) / var(--cc) * var(--co) + var(--co) * var(--gx));
    }

    .col { --cs: var(--cc) }
    .col-auto { --cw: auto }

    @for $i from 1 through $grid-max {
        .col-#{$i} { --cs: #{$i} }

        @if $i < $grid-max {
            .offset-#{$i} { --co: #{$i} }
        }
    }

    .order-first { order: -1 }
    .order-last { order: 999 }

    @for $i from 0 through $grid-max {
        .order-#{$i} { order: $i }
    }
  
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint);  
  
        @include media-breakpoint-up($breakpoint) {
            .col#{$infix} { --cs: var(--cc) }
            .col#{$infix}-auto { --cw: auto }
    
            @for $i from 1 through $grid-max {
                .col#{$infix}-#{$i} { --cs: #{$i} }

                @if $i < $grid-max {
                    .offset#{$infix}-#{$i} { --co: #{$i} }
                }
            }
    
            .order#{$infix}-first { order: -1 }
            .order#{$infix}-last { order: 999 }
    
            @for $i from 0 through $grid-max {
                .order#{$infix}-#{$i} { order: $i }
            }
        }
    }
}