/*
|
| FEATURES
|----------------
|
*/
$features: (
    // GRID
    'grid':                true,
    'flex-directions':     true,
    'flex-wraps':          true,
    'flex-aligns':         true,
    'flex-justifys':       true,
    // LAYOUT
    'displays':            true,
    'containers':          true,
    'ratios':              true,
    // PADDINGS & MARGINS
    'spaces':              true,
    'spaces-child':        true,
    // BACKGROUNDS & IMAGES
    'background-colors':   true,
    'background-images':   true,
    'images':              true,
    // TEXTS
    'titles':              true,
    'text-aligns':         true,
    'text-transforms':     true,
    'text-decorations':    false,
    'font-families':       true,
    'font-sizes':          true,
    'font-weights':        true,
    'font-styles':         false,
    'line-heights':        true,
    'white-spaces':        false,
    'letter-spacings':     true,
    'colors':              true,
    // MISC
    'overflows':           true,
    'positions':           true,
    'pointer-events':      false,
    'floats':              false,
    'transforms':          false,
    'optimize-animations': false,
    'absolute-full':       true,
    'width-height':        true,
    'overlay':             true,
);

/*
|
| UTILS
|----------------
|
*/
@import 'core/functions';

/*
|
| MIXINS
|----------------
|
*/
@import 'mixins/global';
@import 'mixins/breakpoints';
@import 'mixins/containers';
@import 'mixins/displays';
@import 'mixins/grid';
@import 'mixins/spaces';
@import 'mixins/ratios';
@import 'mixins/texts';
@import 'mixins/titles';


/*
|
| VARIABLES
|----------------
|
*/
@import 'variables/grid';
@import 'variables/spaces';
@import 'variables/colors';
@import 'variables/texts';
@import 'variables/titles';


/*
|
| MAPS
|----------------
|
*/
@import 'maps/global';
@import 'maps/breakpoints';
@import 'maps/containers';
@import 'maps/displays';
@import 'maps/grid';
@import 'maps/spaces';
@import 'maps/colors';
@import 'maps/ratios';
@import 'maps/texts';
@import 'maps/titles';


/*
|
| CORE
|----------------
|
*/
@import 'core/helpers';
@import 'core/generator';