/*
|-------------------------------
| Container classes creation
|-------------------------------
| Variables used to generate container classes
| - Generated classes: .container / .container-fluid
|
*/
$containers: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 80rem // 1280px
);