/*
|--------------------
|   PAGE MAGAZINE
|--------------------
*/
#page-magazine{

    .featured-news{

        position: relative;
        margin-top: -50vh;

        .container{

            --gx: 32px;
            --gy: 32px;

            display: grid;
            // grid-template-columns: 66.66% 33.33%;
            grid-template-columns: auto 33.33%;
            gap: var(--gy) var(--gx);

            @include media-breakpoint-down(lg){

                grid-template-columns: repeat(2, 1fr);

            }

            @include media-breakpoint-down(md){

                grid-template-columns: 1fr;

            }

            .news-1{

                grid-column: 1 / 2;
                grid-row: 1 / 3;

                @include media-breakpoint-down(lg){

                    grid-column: 1 / 3;
                    grid-row: 1;

                }

                @include media-breakpoint-down(md){

                    grid-column: inherit;
                    grid-row: inherit;

                }

                & > *{

                    position: sticky;
                    top: calc( var(--header-height) + 10px );

                }

            }

            .news-2{

                grid-column: 2;
                grid-row: 1;

                @include media-breakpoint-down(lg){

                    grid-column: 1 / 1;
                    grid-row: 2;

                }

                @include media-breakpoint-down(md){

                    grid-column: inherit;
                    grid-row: inherit;

                }

            }

            .news-3{

                grid-column: 2;
                grid-row: 2;

                @include media-breakpoint-down(lg){

                    grid-column: 2 / 2;
                    grid-row: 2;

                }

                @include media-breakpoint-down(md){

                    grid-column: inherit;
                    grid-row: inherit;

                }

            }

        }

    }

    .news{

        padding-top: 56px;
        padding-bottom: 144px;

        &-container{

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 56px 32px;

            @include media-breakpoint-down(lg){

                grid-template-columns: repeat(2, 1fr);

            }

            @include media-breakpoint-down(md){

                grid-template-columns: 1fr;

            }

        }

    }

}