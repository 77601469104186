/*
|--------------------
|     PRODUCTS
|      (Home)
|--------------------
*/
.section-products{

    --pbs: clamp(2.5rem, 4.1666666667vw, 3.75rem);
    --pbe: clamp(3.75rem, 6.25vw, 5.625rem);

    padding-block: var(--pbs) var(--pbe);

    // padding: 28px 0 90px;

    // @include media-breakpoint-down(lg){

    //     padding-block: 80px;

    // }

    // @include media-breakpoint-down(md){

    //     padding-block: 60px;

    // }

    .universe-list{

        margin-top: 90px;

        & > *{

            gap: 24px 48px;

        }

    }

    .universe-list-item{

        position: relative;
        opacity: .2;
        transition: opacity .3s $easeSmooth;
        cursor: pointer;

        &::after{

            content: '';
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            width: 0;
            height: 1px;
            background-color: $black;
            transition: all .3s $easeSmooth;

        }

        &:hover{

            &:not(.active){

                opacity: .6;

            }

            &::after{

                width: 100%;

            }

        }

        &.active{

            opacity: 1;

            &::after{

                width: 100%;

            }

        }

        &.no-pointer{

            cursor: default;

        }

    }

    .universe-items{

        margin-top: 80px;

    }

    .universe-item{

        display: none;
        opacity: 0;
        visibility: hidden;

        &.active{

            display: block;
            opacity: 1;
            visibility: visible;

        }

        .universe-related-type{

            .card-container{

                overflow: hidden;
                z-index: 0;

                &::after{

                    content: '';
                    position: absolute;
                    z-index: 2;
                    inset: 0;
                    background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%);

                }

                img{

                    z-index: 1;
                    opacity: .95;

                }

            }

            .card-content{

                position: absolute;
                inset: 0;
                z-index: 3;
                padding: 60px 70px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                transform: translate3d(0, 0, 0);
                transition:
                    transform .3s $easeSmooth,
                    opacity 0 $easeSmooth,
                    visibility 0 $easeSmooth
                ;

                &:not(.content-hover){

                    opacity: 1;
                    visibility: visible;

                }

                &.content-hover{

                    opacity: 0;
                    transform: translate3d(0, 100%, 0);
                    transition: all 1.2s $easeSmooth;

                    .type-description{

                        max-height: 80%;
                        overflow: scroll;
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */

                        &::-webkit-scrollbar {
                            display: none;
                        }

                    }

                }

            }

            &:nth-child( 3n + 1){

                // @include media-breakpoint-up(lg){

                //     margin-top: 80px;

                // }

                margin-top: 80px;

                @include media-breakpoint-down(lg){

                    margin-top: 0;

                }

            }

            &:nth-child( 3n + 3){

                // @include media-breakpoint-up(lg){

                //     margin-top: 80px;

                // }

                margin-top: 80px;

                @include media-breakpoint-down(lg){

                    margin-top: 0;

                }

            }

            &:nth-child( 2n + 1){

                @include media-breakpoint-down(lg){

                    margin-top: 80px;

                }

                @include media-breakpoint-down(md){

                    margin-top: 0;

                }

            }

            &:hover{

                .card-container{

                    &::after{

                        opacity: 1;
                        visibility: visible;

                    }

                }

                .card-content{

                    transform: translate3d(0, -100%, 0);

                    &:not(.content-hover){

                        opacity: 0;
                        visibility: hidden;

                    }

                    &.content-hover{

                        opacity: 1;
                        transform: translate3d(0, 0, 0);

                    }

                }

            }

        }

    }

}