.double_cta {
    position: relative;

    &-image {
        position: absolute;
        inset: 80px 0;
        z-index: 1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-container {
        position: relative;
        z-index: 2;
    }

    &-item {
        display: flex;

        &:nth-child(even) {
            justify-content: flex-end;
        }

        &:not(:first-child) {
            margin: {
                top: -56px;
            }
        }
    }

    &-content {
        width: calc(50% - 16px);

        padding: 56px;

        color: $white;
        text-align: center;

        background-color: $black;

        &--white {
            color: $black;

            background-color: $white;
        }
    }

    @include media-breakpoint-down(lg) {
        &-item {
            &:not(:first-child) {
                margin: {
                    top: 32px;
                }
            }
        }

        &-content {
            width: calc(75% - 16px);
        }
    }

    @include media-breakpoint-down(md) {
        &-content {
            width: 100%;
            padding: 24px;
        }
    }
}