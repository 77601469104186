.slider_product {
    position: relative;

    overflow: hidden;

    &:after {
        content: '';

        position: absolute;
        top: 0;
        left: -10px;
        bottom: 0;
        z-index: 1;

        background-color: $white;

        width: calc((100% - #{map-get($containers, xl)}) / 2);
    }

    &-header, &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > *:not(div) {
            flex: 1;
        }
    }

    &-header{

        gap: .5rem;

        @include media-breakpoint-down(sm){

            flex-direction: column;
            align-items: flex-start;

        }

    }

    &-slider {
        margin: 40px 0;

        overflow: visible;
    }

    &-pagination {
        position: relative;

        display: flex;
        align-items: center;

        &-cursor {
            position: absolute;
            top: 0;
            left: 14px;
            bottom: 0;

            width: 32px;
            height: 32px;

            border: 1px solid $black;
            border-radius: 50%;

            transform: translateX(-14px);
        }

        &-item {
            min-width: 4px;
            height: 4px;

            margin: 14px;

            background-color: $black;
            border-radius: 50%;
        }
    }

    &-grid{

        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        gap: 2rem;

    }

    @include media-breakpoint-down(lg) {
        &:after {
            display: none;
        }
    }
}