/*
|-------------
| Breakpoints media queries creation 
|-------------
| - How to use: add lines to create more breakpoints
|
*/
$breakpoints: (
    sm: 36rem, // 576px
    md: 48rem, // 768px
    lg: 64rem, // 1024px
    xl: 80rem, // 1280px
    xxl: 90rem, // 1440px
);