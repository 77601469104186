/*
|--------------------
|      SINGLE
|--------------------
*/

.back-btn{

    $btn-size: 32px;
    $btn-space: 24px;

    font-size: .75rem;
    line-height: .75rem;

    height: .75rem;

    letter-spacing: 3px;

    position: relative;
    padding-left: calc($btn-size + $btn-space);

    &::before,
    &::after{

        top: 50%;
        transform: translateY(-50%);

    }

    &::before{

        content: '';
        position: absolute;
        left: 0;
        width: $btn-size;
        height: $btn-size;
        border-radius: $btn-size;
        border: 1px solid $white;
        background-color: $black;

    }

    &::after{

        content: '';
        position: absolute;
        left: 0;
        width: $btn-size;
        height: $btn-size;
        border-radius: $btn-size;
        background-color: $white;
        transform: translate3d(0, -50%, 0) rotate(90deg);
        mask:
            url('../img/icon/chevron.svg')
            // center / 100% auto
            center / 8px 4px
            no-repeat
        ;
        -webkit-mask:
            url('../img/icon/chevron.svg')
            // center / 100% auto
            center / 8px 4px
            no-repeat
        ;

    }

    &:hover{

        &::before{

            background-color: $white;

        }

        &::after{

            background-color: $black;

        }

    }

}

// .post-date {

//     font-size: 12px;
//     color: rgba($grey, 0.6);
//     text-transform: uppercase;
//     font-weight: 400;

// }

.post-excerpt {

    font-size: 22px;
    color: rgba($very-dark-grey, 0.6);
    font-weight: 400;

}

#page-news-single{

    .related-news{

        padding-top: 80px;
        padding-bottom: 177px;

        @include media-breakpoint-down(lg){
            padding: {
                bottom: 80px;
            }
        }

        .related-back-link{

            @include media-breakpoint-down(sm){

                margin-top: 15px;

            }

        }

        .container-news{

            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 56px 32px;

            @include media-breakpoint-down(lg){

                grid-template-columns: repeat(2, 1fr);

            }

            @include media-breakpoint-down(md){

                grid-template-columns: 1fr;

            }

        }

    }

}

#page-stores-single{

    .banner-store{

        .coordinates{

            padding: 55px 60px 55px 42px;
            transform: translate3d(0, 25px, 0);

            @include media-breakpoint-down(sm){

                width: 100%;

            }

            .address{

                --picto-size: 32px;
                --picto-space: 24px;

                position: relative;
                display: block;
                margin-top: 40px;
                padding-left: calc(var(--picto-size) + var(--picto-space));
                padding-block-end: 40px;
                border-bottom: 1px solid $black;

                &::before,
                &::after{

                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: var(--picto-size);
                    height: var(--picto-size);
                    border-radius: var(--picto-size);
                    background-color: $black;

                }

                &::before{

                    background-color: $black;

                }

                &::after{

                    background-color: $white;

                    mask:
                        url('../img/icon/marker.svg')
                        center / auto
                        no-repeat
                    ;
                    -webkit-mask:
                        url('../img/icon/marker.svg')
                        center / auto
                        no-repeat
                    ;

                }

                @include media-breakpoint-down(md) {
                    padding: {
                        bottom: 16px;
                    }
                }

            }

            .schedule{

                margin-top: 40px;

                @include media-breakpoint-down(md) {
                    margin: {
                        top: 16px;
                    }
                }

            }

            .others-cta{

                gap: 10px 24px;

                align-items: stretch;

                .btn{

                    padding-inline: 20px;
                    flex-grow: 1;
                    flex-shrink: 0;

                }

            }

        }

    }

}