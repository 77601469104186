/*
|--------------------
|      CONTENT
|--------------------
*/



/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: block;
    }

    img {
        will-change: transform;
        transition: transform 2s $easeOutExpo;
    }

    /*
    * Hover state
    */
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}


/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: var(--header-height);
}


/*
|
| Lang Container
|--------------
*/
.lang-container{

    $icon-space: 7px;
    $icon-size: 6px;

    position: relative;
    display: inline-flex;
    @extend %link-menu;
    text-transform: uppercase;
    cursor: pointer;
    padding-right: calc($icon-space + $icon-size );

    &::after{

        content: '';
        position: absolute;
        top: 8px;
        right: 0;
        width: $icon-size;
        height: $icon-size;
        background-color: $white;
        mask:
            url('../img/icon/chevron.svg')
            center / 100% auto
            no-repeat
        ;
        -webkit-mask:
            url('../img/icon/chevron.svg')
            center / 100% auto
            no-repeat
        ;

    }

    .current-lang{

        display: flex;
        justify-content: center;
        align-items: center;

    }

    .flag{

        $flag-size: 12px;

        position: relative;
        width: $flag-size;
        height: $flag-size;
        border-radius: $flag-size;
        margin-right: 8px;
        overflow: hidden;

        img{

            display: block;
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;

        }

    }

    ul {

        position: absolute;
        z-index: 1;
        top: 100%;
        // left: 0;
        right: calc($icon-space + $icon-size );
        padding: .5rem;
        background-color: $black;
        opacity: 0;
        visibility: hidden;
        transform: translateX(10px);
        transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;

    }

    &:hover {

        ul {

            opacity: 1!important;
            visibility: visible!important;
            transform: translateX(0)!important;

        }

    }

}


/*
|
| CMS
|------
*/
.cms {
    p,
    ul, ol,
    h1, h2, h3, h4, h5, h6 {
        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    @if is-enabled('titles') {
        h1{
            @extend .title-xxl;
        }
        h2 {
            @extend .title-xl;
        }
        h3 {
            @extend .title-lg;
        }
        h4 {
            @extend .title-md;
        }
        h5 {
            @extend .title-sm;
        }
        h6 {
            @extend .title-xs;
        }
    }

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    ul,
    ol {
        padding-left: 1.25em;

        li {
            &:not(:first-child) {
                margin-top: .25em;
            }
        }
    }

    ul {
        li {
            list-style: disc;

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        li {
            list-style: decimal;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md){
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;

            background-image: url('../img/icon/quote-left.svg');

            width: 50px;
            height: 50px;


            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url('../img/icon/quote-right.svg');

            width: 50px;
            height: 50px;


            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md){
                transform: translateX(60px) translateY(-50px);
            }
        }
    }

    hr {
        margin: 24px 0;

        height: 1px;

        background-color: $very-light-grey;
        border: none;
    }

    @include media-breakpoint-down(lg) {
        hr {
            margin: 8px 0;
        }
    }
}


/*
|
| loading
|--------------
*/
.loading {
    $el: &;

    position: relative;

    padding: {
        bottom: 50px;
    }

    &:after {
        content: '';

        position: absolute;
        bottom: 22px;
        left: 0;
        right: 0;

        height: 2px;

        background-color: $black;

        animation: loading 1s linear infinite;
    }

    @keyframes loading {
        0%   { left: 40%; right: 40%; }
        45%  { left: 60%; right: 40%; }
        55%  { left: 40%; right: 60%; }
        100% { left: 40%; right: 40%; }
    }
}

.loading-bottom {
    @extend .loading;

    padding: {
        top: 0;
    }

    &:before {
        top: auto;
        bottom: 22px;
    }
}


/*
|
| sub title
|--------------
*/
.sub-title {
    font-family: $font-family-custom;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.dn-desktop-t{

    @include media-breakpoint-up(md){

        display: none;

    }

}

.dn-desktop-m{

    @include media-breakpoint-up(sm){

        display: none;

    }

}

.dn-tablet{

    @include media-breakpoint-down(md){

        display: none;

    }

}

.dn-mobile{

    @include media-breakpoint-down(sm){

        display: none;

    }

}

.title-lines{

    .title-line{

        display: block;
        width: 100%;

    }

}