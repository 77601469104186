/*
|-------------------------------
| Title sizes classes creation
|-------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
$title-sizes: (
  'xs-small': (
    'min': .75rem,
    'max': .75rem,
  ),
  'xs': (
    'min': .875rem,
    'max': .875rem,
  ),
  'sm-small': (
    'min': 1rem,
    'max': 1rem,
  ),
  'sm': (
    'min': 1.125rem,
    'max': 1.125rem,
  ),
  'md': (
    'min': 1.5rem,
    'max': 1.5rem,
  ),
  'lg': (
    'min': 1.875rem,
    'max': 2rem,
  ),
  'xl-small': (
    'min': 1.875rem,
    'max': 2.5rem,
  ),
  'xl': (
    'min': 1.875rem,
    'max': 3rem,
  ),
  'xxl': (
    'min': 2rem,
    'max': 5rem,
  )
);