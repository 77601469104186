/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
*{
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body{
    font-family: $font-family-default;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    background-color: $white;
    color: $default-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

button,
input,
select,
textarea {
    border: 0;
    border-radius: 0;

    background-color: transparent;

    appearance: none;

    letter-spacing: inherit;
    font-family: inherit;
    font-size: inherit;
}

a,
button,
select,
input,
textarea {
    color: inherit;

    &:focus,
    &:focus-visible {
        outline: none;
        outline-offset: 0;
    }
}

a,
button {
    cursor: pointer;
}

button {
    text-transform: inherit;
}

a {
    text-decoration: none;
}

ol,
ul {
  li{
    list-style-type: none;
  }
}

ul{
    margin: 0;
    padding: 0;

    li{
        list-style-type: none;
    }
}