/*
|--------------------
|     Stores
|--------------------
*/

#page-stores{

    .banner-stores{

        min-height: auto;
        margin-bottom: -160px;

    }

    .section-showrooms-locator{

        position: relative;
        z-index: 2;
        margin-top: 80px;

        .showrooms-container{

            display: grid;
            gap: 72px;

            .showrooms-infos{

            }

            .showrooms-content{

            }

            .find-showroom{

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 600px;
                gap: 32px;

                @include media-breakpoint-down(lg){

                    grid-template-columns: 1fr;

                }

                .left{

                    display: grid;
                    gap: 32px;

                }

                .search{

                    --pictoWidth: 12px;
                    --pictoSpace: 18px;

                    position: relative;
                    padding-left: calc(var(--pictoWidth) + var(--pictoSpace));
                    border-bottom: 1px solid $black;

                    &::after{

                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                        background-color: $black;

                    }

                    &-icon{
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;

                        width: calc(var(--pictoWidth) + var(--pictoSpace));
                        height: 100%;

                        cursor: pointer;

                        &:before {
                            content: '';

                            position: absolute;
                            top: 50%;
                            left: 0;

                            transform: translate3d(0, -50%, 0);

                            background-color: $black;

                            width: var(--pictoWidth);
                            height: var(--pictoWidth);

                            mask:
                                url('../img/icon/magnifying-glass.svg')
                                center / 100% auto
                                no-repeat
                            ;
                            -webkit-mask:
                                url('../img/icon/magnifying-glass.svg')
                                center / 100% auto
                                no-repeat
                            ;
                        }

                    }

                    &:after{

                        left: calc(var(--pictoWidth) + var(--pictoSpace));
                        width: 1px;
                        height: 16px;

                    }

                    input{

                        appearance: none;
                        width: 100%;
                        padding: 12px 16px;
                        line-height: 22px;

                    }

                }

                .list{

                    display: flex;
                    gap: 32px;
                    flex-direction: column;

                    overflow-y: auto;

                }

                #map-showrooms{

                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: $black;

                    @include media-breakpoint-down(lg){

                        aspect-ratio: 1 / 1;

                    }

                }

            }

        }

        &.active{

            display: block;

        }

    }

    .section-concept-stores{

    }

}
