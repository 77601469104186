/*
|-------------------------------
| Display classes creation
|-------------------------------
| Variables used to generate display classes
| - Generated classes: .d-{display}
| - Exemple: .d-f
|
*/
$displays: (
    'none': none,
    'i':    inline,
    'b':    block,
    'ib':   inline-block,
    'f':    flex,
    'if':   inline-flex
);