@mixin generate-class-from-map($map, $class-prefix, $css-prop, $important: '') {
    @each $class-value, $css-val in $map {
        .#{$class-prefix}-#{$class-value} {
           #{$css-prop}: $css-val #{$important};
        }
    }
}
  
@mixin generate-screen-class-from-map($class-prefix-screen, $class-prefix-prop, $class-prefix-val, $css-props) {
    @if $class-prefix-screen != '' {
        $class-prefix-screen: #{$class-prefix-screen}-;
    }

    @if $class-prefix-prop != '' {
        $class-prefix-prop: #{$class-prefix-prop}-;
    }

    $class: #{$class-prefix-screen}#{$class-prefix-prop}#{$class-prefix-val};

    .#{$class} {
        @each $prop, $val in $css-props {
            #{$prop}: $val;
        }
    }
}
  
@mixin generate-screen-class-from-map_($class-prefix-screen, $class-prefix-prop, $class-prefix-val, $css-prop, $css-val) {
    $class: false;

    @if $class-prefix-prop == ''{
        $class: #{$class-prefix-screen}-#{$class-prefix-val};
    } @else {
        $class: #{$class-prefix-screen}-#{$class-prefix-prop}-#{$class-prefix-val};
    }

    .#{$class} {
        #{$css-prop}: $css-val;
    }
}
  
@mixin generate-properties-from-map($props) {
    @each $prop, $val in $props {
        #{$prop}: $val;
    }
}