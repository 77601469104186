/*
|--------------------
|     AUTHOR
|--------------------
*/
.flexible-author{

    overflow: hidden;

    .author-img{

        $trombi-size: 96px;

        position: relative;
        z-index: 0;
        margin: 0 auto;

        &::before{

            content: '';
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate3d(-60%, -50%, 0);
            width: 1000%;
            height: 1px;
            background-color: $very-light-grey;

        }

        img{

            max-width: $trombi-size;
            border-radius: $trombi-size;

            object-fit: cover;
            object-position: center;

        }

    }

    .author-name{

    }

    .author-job{

    }

}