.card-store{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    &.showroom {
        background-color: $black;

        &.--not-visible {
            background-color: $light-grey;
        }
    }

    .address{

        --picto-size: 32px;
        --picto-space: 24px;

        position: relative;
        padding-left: calc(var(--picto-size) + var(--picto-space));

        &::before,
        &::after{

            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate3d(0, -50%, 0);
            width: var(--picto-size);
            height: var(--picto-size);
            border: 1px solid $black;
            border-radius: var(--picto-size);

        }

        &::before{

            background-color: $white;

        }

        &::after{

            background-color: $black;

            mask:
                url('../img/icon/marker.svg')
                center / auto
                no-repeat
            ;
            -webkit-mask:
                url('../img/icon/marker.svg')
                center / auto
                no-repeat
            ;

        }

    }

    .separator{

        height: 1px;
        background-color: $white;

    }

    .schedule{

    }

    .cta-showroom{

        align-self: flex-start;

    }

    &.concept-store{

        height: 100%;
        justify-content: space-between;

        .content{

            display: flex;
            flex-direction: column;
            gap: 24px;

        }

        .address{

            &::before{

                background-color: $black;

            }

            &::after{

                background-color: $white;

            }

        }

        .btn{

            align-self: flex-start;

        }

    }

}