/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/


/*
|
| CONTAINERS
|----------------
|
*/
@if is-enabled('containers') {
    @include generate-containers();
}


/*
|
| GRID
|----------------
|
*/
@if is-enabled('grid') {
    @include generate-grid-rows();
    @include generate-grid-columns();
    @include generate-grid-gutters();
}


/*
|
| DISPLAYS
|----------------
|
*/
@if is-enabled('displays') {
    @include generate-displays();
}


/*
|
| MARGINS & PADDINGS
|----------------
|
*/
@if is-enabled('spaces') {
    @include generate-spaces();
}

@if is-enabled('spaces-child') {
    @include generate-spaces-child();
}


/*
|
| RATIOS
|----------------
|
*/
@if is-enabled('ratios') {
    @include generate-ratios();
}


/*
|
| BACKGROUND COLORS
|----------------
|
*/
@if is-enabled('background-colors') {
    @include generate-class-from-map(
        $colors, // maps/_colors.scss
        'bg',
        background-color
    );
}


/*
|
| TEXT ALIGNS
|----------------
|
*/
@if is-enabled('text-aligns') {
    @include generate-text-alignments();
}


/*
|
| FONT FAMILIES
|----------------
|
*/
@if is-enabled('font-families') {
    @include generate-class-from-map(
        $font-families, // maps/_texts.scss
        'ff',
        font-family
    );
}


/*
|
| FONT SIZES
|----------------
|
*/
@if is-enabled('font-sizes') {
    @include generate-font-sizes();
}


/*
|
| FONT WEIGHTS
|----------------
|
*/
@if is-enabled('font-weights') {
    @include generate-class-from-map(
        $font-weights, // maps/_texts.scss
        'fw',
        font-weight
    );
}


/*
|
| LINE HEIGHTS
|----------------
|
*/
@if is-enabled('line-heights') {
    @include generate-class-from-map(
        $line-heights, // maps/_texts.scss
        'lh',
        line-height
    );
}


/*
|
| LETTER SPACING
|----------------
|
*/
@if is-enabled('letter-spacings') {
    @include generate-class-from-map(
        $letter-spacings, // maps/_texts.scss
        'ls',
        letter-spacing
    );
}


/*
|
| TITLES
|----------------
|
*/
@if is-enabled('titles') {
    @include generate-titles();
}


/*
|
| COLORS
|----------------
|
*/
@if is-enabled('colors') {
    @include generate-class-from-map(
        $colors, // maps/_colors.scss
        'c',
        color
    );
}