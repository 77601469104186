/*
|--------------------
|       Instagram (feed)
|--------------------
*/
.flexible-instagram-feed{

    --pb: 80px;

    padding-block: var(--pb);

    @include media-breakpoint-down(md){

        --pb: 60px;

    }

    @include media-breakpoint-down(sm){

        --pb: 40px;

    }

    .ig-head{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 16px;

        @include media-breakpoint-down(md) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    #sb_instagram {
        display: none;
    }

    .ig-item {
        display: block;
        width: 100%;
        aspect-ratio: 1;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

}