/*
|--------------------
|     SLIDERS
|      (Home)
|--------------------
*/
.section-sliders-home{

    padding: 116px 0 127px;

    @include media-breakpoint-down(lg){

        padding-block: 80px;

    }

    .row-sliders-main{

    }

    .row-sliders{

        @include media-breakpoint-up(lg){
            
            --gy: 120px;

        }

        & > *{

            display: flex;

            &:nth-child(1){

                justify-content: flex-end;
                align-items: flex-end;

            }

            &:nth-child(2){

                justify-content: flex-end;
                align-items: flex-end;

            }

            &:nth-child(3){

                justify-content: flex-start;
                align-items: flex-start;

            }

            &:nth-child(4){

                justify-content: flex-start;
                align-items: flex-start;

            }

        }

    }

    .col-universe{

        position: relative;
        min-width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .slider-universe{

            width: 100%;
            height: 100%;

            .swiper-slide {
                &:before {
                    content: '';

                    position: absolute;
                    inset: 0;
                    z-index: -1;

                    background-image: linear-gradient(0deg, rgba($black, 1) 0%, rgba($black, 0) 100%);

                    opacity: .5;
                }
            }

        }

        // .universe-name{

        //     position: absolute;
        //     bottom: 66px;

        // }

        &.big{

            aspect-ratio: 623 / 833;

            &:not(.has-slider){

                padding: 50px;

            }

        }

        &.small{

            aspect-ratio: 514 / 687;

            &:not(.has-slider){

                padding: 40px;

            }

        }

    }

    &.has-content{

        .row-sliders-main{

            margin-top: 60px;

        }

    }

}