/*
|---------------------------------------------------------------
| FONTS
|---------------------------------------------------------------
| Set all font properties
|
|
*/


/*
|
| FAMILIES
|----------------
|
*/
$font-family-default: 'graphik', sans-serif;
$font-family-custom:  'Roxborough CF', sans-serif;
$font-family-extra:   'graphik', sans-serif;


/*
|
| SIZES
|----------------
|
*/
$font-size-xs-small:     .75rem;                     // 12px
$screen-xl-font-size-xs-small: $font-size-xs-small;  // 12px
$screen-lg-font-size-xs-small: $font-size-xs-small;  // 12px
$screen-md-font-size-xs-small: $font-size-xs-small;  // 12px
$screen-sm-font-size-xs-small: $font-size-xs-small;  // 12px

$font-size-xs:            .875rem;        // 14px
$screen-xl-font-size-xs:  $font-size-xs;  // 14px
$screen-lg-font-size-xs:  $font-size-xs;  // 14px
$screen-md-font-size-xs:  $font-size-xs;  // 14px
$screen-sm-font-size-xs:  $font-size-xs;  // 14px

$font-size-sm-small:      1rem;                       // 16px
$screen-xl-font-size-sm-small:  $font-size-sm-small;  // 16px
$screen-lg-font-size-sm-small:  $font-size-sm-small;  // 16px
$screen-md-font-size-sm-small:  $font-size-sm-small;  // 16px
$screen-sm-font-size-sm-small:  $font-size-sm-small;  // 16px

$font-size-sm:            1.125rem;       // 18px
$screen-xl-font-size-sm:  $font-size-sm;  // 18px
$screen-lg-font-size-sm:  $font-size-sm;  // 18px
$screen-md-font-size-sm:  $font-size-sm;  // 18px
$screen-sm-font-size-sm:  $font-size-sm;  // 18px

$font-size-md:            1.5rem;         // 24px
$screen-xl-font-size-md:  $font-size-md;  // 24px
$screen-lg-font-size-md:  $font-size-md;  // 24px
$screen-md-font-size-md:  $font-size-md;  // 24px
$screen-sm-font-size-md:  $font-size-md;  // 24px

$font-size-lg:            2rem;           // 32px
$screen-xl-font-size-lg:  $font-size-lg;  // 32px
$screen-lg-font-size-lg:  $font-size-lg;  // 32px
$screen-md-font-size-lg:  $font-size-lg;  // 32px
$screen-sm-font-size-lg:  $font-size-lg;  // 32px

$font-size-xl-small:      2.5rem;                     // 40px
$screen-xl-font-size-xl-small:  $font-size-xl-small;  // 40px
$screen-lg-font-size-xl-small:  $font-size-xl-small;  // 40px
$screen-md-font-size-xl-small:  $font-size-xl-small;  // 40px
$screen-sm-font-size-xl-small:  $font-size-xl-small;  // 40px

$font-size-xl:            3rem;           // 48px
$screen-xl-font-size-xl:  $font-size-xl;  // 48px
$screen-lg-font-size-xl:  $font-size-xl;  // 48px
$screen-md-font-size-xl:  $font-size-xl;  // 48px
$screen-sm-font-size-xl:  $font-size-xl;  // 48px

$font-size-xxl:           5rem;           // 80px
$screen-xl-font-size-xxl: $font-size-xxl; // 80px
$screen-lg-font-size-xxl: $font-size-xxl; // 80px
$screen-md-font-size-xxl: $font-size-xxl; // 80px
$screen-sm-font-size-xxl: $font-size-xxl; // 80px


/*
|
| LINE-HEIGHT
|----------------
|
*/
$line-height-xs:  1;
$line-height-sm:  1.2;
$line-height-md:  1.5;
$line-height-lg:  1.6;
$line-height-xl:  1.8;
$line-height-xxl: 2;


/*
|
| LETTER-SPACING
|----------------
|
*/
$letter-spacing-xs:  1px;
$letter-spacing-sm:  2px;
$letter-spacing-md:  3px;
$letter-spacing-lg:  4px;
$letter-spacing-xl:  5px;
$letter-spacing-xxl: 6px;


/*
|
| DEFAULTS
|----------------
|
*/
$default-font-size:   $font-size-xs;
$default-color:       $text-color;
$default-line-height: $line-height-xl;
$default-font-weight: 300;