.slider_atelier {

    &--black {
        color: $white;

        background-color: $black;

        .slider_atelier-icon {
            svg path {
                stroke: $white;
            }
        }

        .slider_atelier-pagination-item {
            &:before {
                background-color: $white;
            }
        }
    }

    &-images {
        &-item {
            aspect-ratio: 5 / 3;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &-details {
        display: flex;
        gap: 32px;

        &-item {
            display: flex;
            align-items: center;
            gap: 8px;

            font-size: 10px;
            font-weight: 400;
            letter-spacing: 2px;
            line-height: 10px;
            text-transform: uppercase;

            @include media-breakpoint-down(sm){

                justify-content: flex-start;

            }

        }
    }

    &-icon {

        width: 16px;
        height: 16px;

        svg {

            display: block;
            width: 100%;
            height: auto;

        }
    }

    &-pagination {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: 24px;

        &-cursor {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;

            width: 32px;
            height: 32px;

            border: 1px solid;
            border-radius: 50%;
        }

        &-item {
            position: relative;

            display: flex;
            align-items: center;

            cursor: pointer;

            &:before {
                content: '';

                min-width: 4px;
                height: 4px;

                margin: 14px;

                background-color: $black;
                border-radius: 50%;
            }

            &-off {
                position: absolute;
                top: 50%;
                left: 56px;

                white-space: nowrap;

                transform: translateY(-50%);
            }

            &-top {
                font-family: $font-family-custom;
                font-size: 18px;
            }

            &-bottom {
                font-size: 10px;
                font-weight: 400;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }
    }

    .swiper{

        width: 100%;

    }

    @include media-breakpoint-down(lg) {
        overflow: hidden;

        &-details {
            flex-direction: column;
            gap: 16px;
        }
    }

    @include media-breakpoint-down(md) {
        &-pagination {
            position: relative;

            width: 32px;
            height: 32px;

            transform: rotate(-90deg);
            transform-origin: center;

            &-item {
                &-off {
                    display: none;
                }
            }
        }
    }
}