/*
|--------------------
|     NEWSLETTER
|--------------------
*/
.section-newsletter{

    position: relative;

    padding: 80px 0;

    .overlay {
        background-color: rgba(0, 0, 0, .8);
    }

    .container{

        position: relative;

    }

    .img-parallax{

        max-width: 187px;
        position: absolute;

        @include media-breakpoint-down(lg){

            display: none;

        }

        &.img-left{

            top: -120px;
            left: 0;

        }

        &.img-right{

            bottom: -160px;
            right: 0;

        }

    }

    .gf-news{

        margin-top: 48px;

        input,
        textarea,
        fieldset{

            appearance: none;
            border: none;
            outline: none;

        }

        .gform_body{

            .gform_fields{

            }

            .gfield{

                &:not(:last-child){

                    margin-bottom: 32px;

                }

                .gfield_label{

                    display: none;

                }

                input{

                    &[type="text"],
                    &[type="email"]{

                        width: 100%;
                        border: none;
                        border-bottom: 1px solid $white;
                        padding: 12px 5px;
                        color: $white;
                        text-transform: uppercase;

                        &::placeholder{

                            font-size: 16px;
                            line-height: 24px;
                            color: $very-light-grey;
                            text-align: center;
                            text-transform: uppercase;

                        }

                    }

                }

                &.gfield--type-consent,
                &.rgpd{

                    .ginput_container_consent{

                        position: relative;

                        input[type="checkbox"]{

                            position: absolute;
                            inset: 0;

                            &:checked {

                                & + label {

                                    &::after {

                                        transform: translate3d(1px, 1px, 0) scale(1);

                                    }

                                }

                            }

                        }

                        .gfield_consent_label{

                            $before-size: 16px;

                            position: relative;
                            display: block;
                            padding-left: calc($before-size * 2);
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: $white;

                            &::before,
                            &::after{

                                content: '';
                                position: absolute;
                                top: 4px;
                                left: 0;
                                width: $before-size;
                                height: $before-size;
                                background-color: $white;

                            }

                            &::before{


                            }

                            &::after{

                                transform: translate3d(1px, 1px, 0) scale(0);
                                width: calc($before-size - 2px);
                                height: calc($before-size - 2px);
                                background-color: $black;
                                transition: all 0.3s $easeSmooth;

                            }

                            a{

                                &:hover{

                                    text-decoration: underline;

                                }

                            }

                        }

                    }

                    .gfield_consent_description{

                        border: none;
                        color: $white;
                        overflow: auto;
                        max-height: none;

                        a{

                            text-decoration: underline;

                        }

                    }

                }

            }

        }

        .gform_footer{

            margin-top: 48px;
            display: flex;
            justify-content: center;

        }

        input[type="submit"]{

            @extend .btn;
            @extend .btn-wtw;

        }

        .gform_confirmation_message{

            text-align: center;
            color: $white;

        }

    }

    .gfield_validation_message, .gform_validation_errors {
        color: $red;
    }

}