.product_card {
    &-image {
        aspect-ratio: 4 / 5;

        border: solid 1px rgba(0,0,0,0.1);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-title {
        font-family: $font-family-custom;
        font-size: 18px;
        text-transform: uppercase;

        margin: 16px 0 8px;
    }
}