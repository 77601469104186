/*
|--------------------
|     WHO WE ARE
|      (Home)
|--------------------
*/
.section-who {

    &-header {
        position: relative;

        display: flex;
        align-items: flex-end;
        justify-content: center;

        aspect-ratio: 9 / 5;

        padding: 220px 0;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        color: $white;

        &:before {
            content: '';

            position: absolute;
            inset: 0;
            z-index: 1;

            background-color: rgba($black, .5);
        }

        &-title {
            position: relative;
            z-index: 2;
        }
    }

    &-main {

    }

    @include media-breakpoint-down(md) {
        &-header {
            aspect-ratio: auto;
        }
    }

}

.swm-item {
    $el: &;

    & + & {
        margin: {
            top: 120px
        }
    }

    position: relative;

    &:nth-child(even) {
        #{$el}-image {
            left: auto;
            right: 0;
        }

        #{$el}-container {
            justify-content: flex-start;
        }
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;

        width: 50%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &-slider {
            height: 100%;

            .swiper-slide {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }

    &-container {
        display: flex;
        justify-content: flex-end;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 50%;
        min-height: 62.5vw;

        padding: 100px;

        &--slider {
            justify-content: space-evenly;
        }

        &-slider {
            width: 100%;

            // &-controls {
            //     position: relative;

            //     display: flex;
            //     gap: 8px;

            //     > div {
            //         position: relative;
            //         inset: auto;

            //         margin: 0;

            //         transform: none;
            //     }
            // }
        }
    }

    @include media-breakpoint-down(lg) {
        &:nth-child(even) {
            #{$el}-content {
                padding: 64px 32px 64px 0;
            }
        }

        &-content {
            padding: 64px 0 64px 32px;
        }
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
        gap: 130px;

        & + & {
            margin: {
                top: 0
            }
        }

        &-image {
            position: static;

            width: 100%;
            aspect-ratio: 1;
        }

        &-content {
            width: 100%;
            min-height: auto;

            padding: 32px 0 !important;
        }
    }
}