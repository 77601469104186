

/*
|
| Banner
|---------
*/
.banner {

    @if is-enabled('background-images') {
        @extend .bg-cover;
    }

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding-block: var(--header-height);
    background-color: $black;

    &.banner-home{

        align-items: flex-end;
        padding-block-end: 60px;

        .overlay {
            background-color: rgba(0, 0, 0, .2);

            &:before {
                content: '';

                position: absolute;
                inset: 0;

                background-image: linear-gradient(180deg, rgba($black, 1) 0%, rgba($black, 0) 100%);

                opacity: .5;
            }
        }

        .row-links{

            margin-top: 160px;

            @include media-breakpoint-down(lg){

                margin-top: 120px;

            }

            @include media-breakpoint-down(md){

                margin-top: 100px;

            }

            @include media-breakpoint-down(sm){

                margin-top: 40px;

            }

            .col{

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 16px 32px;

                .btn{

                    flex-grow: 1;
                    flex-shrink: 1;

                    @include media-breakpoint-down(sm){

                        padding: 16px 40px;

                    }

                }

            }

        }

    }

    &.banner-simple {

        align-items: flex-start;
        padding-block-start: calc(var(--header-height) + 120px);
        padding-block-end: var(--header-height);

    }

    &.banner-single {

        &.banner-news{

            align-items: flex-start;
            padding-top: calc(var(--header-height) + 120px);
            padding-bottom: 8px;

            &::before{

                content: '';
                position: absolute;
                z-index: 0;
                // z-index: -1;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: $beige;

            }

            &::after{

                content: '';
                position: absolute;
                z-index: 0;
                left: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                background-color: $black;

            }

            .container{

                position: relative;
                z-index: 1;

            }

            .post-infos{

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-items: flex-start;

                @include media-breakpoint-down(lg){

                    grid-template-columns: 1fr;
                    justify-items: center;
                    gap: 15px;

                }

                .post-date{

                    @include media-breakpoint-up(lg){

                        transform: translate3d(-10px, 0, 0);

                    }

                }

            }

        }

        &.banner-store{

            z-index: 1;
            align-items: flex-end;
            padding-block-end: 0;
            margin-bottom: 25px;

        }

    }

}