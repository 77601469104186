/*
|--------------------
|       News
|--------------------
*/
.flexible-news{

    --pbs: clamp(2.5rem, 5.5555555556vw, 5rem);
    --pbe: clamp(2.5rem, 13.8888888889vw, 12.5rem);
    
    overflow: hidden;
    padding-block: var(--pbs) var(--pbe);

    &.--no-padding-bottom {
        padding-bottom: 0;
    }

    .col-top{

        gap: 15px 0;

    }

    .row-slider{

    }

    .row-controls{

        margin-top: 40px;

        & > div{

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 30px 0;

            @include media-breakpoint-down(sm){

                align-items: flex-start;
            }

        }

    }

    .swiper{

        overflow: visible;

        &::before{

            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            background-color: $white;

        }

    }

    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next{

        position: relative;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;
        margin: 0;

        height: 32px;

    }

    .swiper-pagination{

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1 1 calc(100% - 80px);
        gap: 32px 0;

    }

    .swiper-buttons{

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 80px;

    }

    .swiper-button-prev,
    .swiper-button-next{

    }

}