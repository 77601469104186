/*
|--------------------
|       CARDS
|--------------------
*/
.card-news{

    .card-img{

        background-color: $black;

    }

    .card-content{

        margin-top: 24px;

        // .content-date{

        //     font-size: 10px;

        // }

        .content-title{

            margin-top: 12px;

        }

        .content-txt{

            margin-top: 12px;

        }

        .content-read-more{

            display: inline-block;
            margin-top: 24px;

        }

    }

    &.featured{

        .card-content{

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            margin-top: 0;
            padding: 40px 24px 32px 24px;

        }

        &.big{

            .card-content{

                padding: 48px 48px 40px 48px;

                .content-title{

                    @extend .title-lg !optional;

                    @include media-breakpoint-down(md){

                        font-size: 1.125rem;

                    }

                }

                .content-txt{

                    @include media-breakpoint-up(md){

                        max-width: 402px;

                    }
                }

                .content-read-more{

                    @extend .btn;
                    @extend .btn-big;

                }

            }

        }

    }

    &.black{

        .card-content{

            background-color: $black;
            color: $white;

            .content-read-more{

                @extend .btn;
                @extend .btn-wbw;

            }

        }

    }

    &.white{

        .card-content{

            border: 1px solid $black;
            background-color: $white;

            .content-read-more{

                @extend .btn;
                @extend .btn-bwb;

            }

        }

    }

}

.card-team{

    .card-img{

        position: relative;

    }

    .card-content{

    }

    &.small{

        .card-img{

            position: relative;

            &::after{

                content: '';
                position: absolute;
                inset: 0;
                background-color: rgba($color: $black, $alpha: 0.7);
                transform: translate3d(0, -100%, 0);
                transition: transform .3s $easeSmooth;

            }

            .bio-container{

                overflow: hidden;
                position: absolute;
                z-index: 1;
                inset: 0;
                padding: 16px;
                transform: translate3d(0, 100%, 0);
                transition: transform .3S $easeSmooth;

                display: flex;
                align-items: flex-end;

            }

            .content-bio{

                width: 100%;
                // height: 100%;
                height: 50%;
                overflow-y: auto;
                // display: flex;
                // align-items: flex-end;

                /* Firefox */
                scrollbar-width: auto;
                scrollbar-color: $light-grey transparent;

                /* Chrome, Edge, and Safari */
                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $light-grey;
                    border-radius: 0px;
                    border: 2px solid transparent;
                }

            }

        }

        .card-content{

            margin-top: 20px;

        }

        &:hover{

            .card-img{

                &::after{

                    transform: translate3d(0, 0, 0);

                }

                .bio-container{

                    transform: translate3d(0, 0, 0);

                }

                .content-bio{

                    transform: translate3d(0, 0, 0);

                }

            }

        }

        &.active{

            .card-img{

                &::after{

                    transform: translate3d(0, 0, 0);

                }

                img{

                    filter: blur(6px);
                }

                .bio-container{

                    transform: translate3d(0, 0, 0);
                    justify-content: center;
                    align-items: center;

                }

                .content-bio{

                    height: auto;

                    max-width: 60%;

                }

            }
        
        }

    }

    &.big{

        display: flex;
        align-items: stretch;

        @include media-breakpoint-down(md){

            flex-wrap: wrap;

        }

        .card-img{

            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 296px;

            @include media-breakpoint-down(md){

                flex-grow: 1;

            }

            @include media-breakpoint-down(sm){

                max-height: 270px;

            }

        }

        .card-content{

            padding: 32px 48px;
            background-color: $black;
            color: $white;
            flex-grow: 1;
            flex-shrink: 1;

            @include media-breakpoint-down(sm){

                padding: 32px 24px;

            }

        }

    }

}

// .card-testimonial{

//     .card-content{

//     }

// }