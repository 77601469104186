/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

/**
 * Roxborough CF
 */

// Light
@font-face {
    font-family: 'Roxborough CF';
    src: url('../fonts/roxborough-cf/roxborough-cf-light.eot');
    src: url('../fonts/roxborough-cf/roxborough-cf-light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roxborough-cf/roxborough-cf-light.woff2') format('woff2'),
        url('../fonts/roxborough-cf/roxborough-cf-light.woff') format('woff'),
        url('../fonts/roxborough-cf/roxborough-cf-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// Regular
@font-face {
    font-family: 'Roxborough CF';
    src: url('../fonts/roxborough-cf/roxborough-cf-regular.eot');
    src: url('../fonts/roxborough-cf/roxborough-cf-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roxborough-cf/roxborough-cf-regular.woff2') format('woff2'),
        url('../fonts/roxborough-cf/roxborough-cf-regular.woff') format('woff'),
        url('../fonts/roxborough-cf/roxborough-cf-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Medium
@font-face {
    font-family: 'Roxborough CF';
    src: url('../fonts/roxborough-cf/roxborough-cf-medium.eot');
    src: url('../fonts/roxborough-cf/roxborough-cf-medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roxborough-cf/roxborough-cf-medium.woff2') format('woff2'),
        url('../fonts/roxborough-cf/roxborough-cf-medium.woff') format('woff'),
        url('../fonts/roxborough-cf/roxborough-cf-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// Bold
@font-face {
    font-family: 'Roxborough CF';
    src: url('../fonts/roxborough-cf/roxborough-cf-bold.eot');
    src: url('../fonts/roxborough-cf/roxborough-cf-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roxborough-cf/roxborough-cf-bold.woff2') format('woff2'),
        url('../fonts/roxborough-cf/roxborough-cf-bold.woff') format('woff'),
        url('../fonts/roxborough-cf/roxborough-cf-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


/**
 * Graphik
 */

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Light.eot');
    src: url('../fonts/graphik/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Light.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Light.woff') format('woff'),
        url('../fonts/graphik/Graphik-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Regular.eot');
    src: url('../fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Regular.woff') format('woff'),
        url('../fonts/graphik/Graphik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Medium.eot');
    src: url('../fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Medium.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Medium.woff') format('woff'),
        url('../fonts/graphik/Graphik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('../fonts/graphik/Graphik-Bold.eot');
    src: url('../fonts/graphik/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/graphik/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/graphik/Graphik-Bold.woff') format('woff'),
        url('../fonts/graphik/Graphik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}