.partners {
    --per-row: 4;
    --col-gap: 32px;
    --row-gap: 48px;

    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    gap: var(--row-gap) var(--col-gap);

    &-item {
        --max: Max(0, calc(var(--per-row) - 1));

        width: calc((100% - var(--max) * var(--col-gap)) / var(--per-row));

        aspect-ratio: 14 / 5;

        object-fit: contain;
        object-position: center;
    }

    @include media-breakpoint-down(lg) {
        --per-row: 3;
    }

    @include media-breakpoint-down(md) {
        --per-row: 2;
        --col-gap: 8px;
        --row-gap: 16px;
    }
}