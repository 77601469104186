@import '../../../../node_modules/swiper/swiper';
@import '../../../../node_modules/swiper/modules/a11y/a11y';
@import '../../../../node_modules/swiper/modules/navigation/navigation';
@import '../../../../node_modules/swiper/modules/pagination/pagination';
// @import '../../../../node_modules/swiper/modules/scrollbar/scrollbar';
// @import '../../../../node_modules/swiper/modules/lazy/lazy';
// @import '../../../../node_modules/swiper/modules/effect-cube/effect-cube';
// @import '../../../../node_modules/swiper/modules/effect-fade/effect-fade';
// @import '../../../../node_modules/swiper/modules/effect-flip/effect-flip';
// @import '../../../../node_modules/swiper/modules/zoom/zoom';

.swiper{

    &[data-swiper="team"]{

    }

    &[data-swiper="universe"]{

        .swiper-slide{

            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .style-name{

                margin-top: 8px;

            }

        }

        .swiper-button-prev,
        .swiper-button-next{

            // $btn-size: 50px;

            // width: $btn-size;
            // height: $btn-size;
            border-color: $white;
            top: inherit;

            &::after{

                border-color: $white;

            }

            &:hover {
                background-color: $white;

                &:after {
                    border-color: $black;
                }
            }

        }

        &.big{

            $offset: 50px;

            .swiper-slide{

                // aspect-ratio: 623 / 833;
                padding: $offset;

            }

            .swiper-button-prev,
            .swiper-button-next{

                bottom: $offset;

                $btn-size: 50px;

                width: $btn-size;
                height: $btn-size;

                &:after {
                    width: 8px;
                    height: 8px;
                }

            }

            .swiper-button-prev{

                left: $offset;

            }

            .swiper-button-next{

                right: $offset;

            }

        }

        &.small{

            $offset: 40px;

            .swiper-slide{

                // aspect-ratio: 514 / 687;
                padding: $offset;

            }

            .swiper-button-prev,
            .swiper-button-next{

                bottom: $offset;

            }

            .swiper-button-prev{

                left: $offset;

            }

            .swiper-button-next{

                right: $offset;

            }

        }

    }

}

.swiper-custom-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swiper-pagination-custom {
    position: relative;
    bottom: 0;
    z-index: 2;

    display: flex;
    align-items: center;

    &-cursor {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: 32px;
        height: 32px;

        border: 1px solid $black;
        border-radius: 50%;
    }

    &-item {
        position: relative;

        width: 32px;
        min-height: 32px;

        cursor: pointer;

        &:before {
            content: '';

            position: absolute;
            inset: 0;

            width: 4px;
            height: 4px;

            margin: auto;

            background-color: $black;
            border-radius: 50%;
        }
    }
}

.swiper-pagination-horizontal{

    &.swiper-pagination-bullets{

        .swiper-pagination-bullet{

            margin: 0 14px;

        }

    }

}

.swiper-pagination-bullet{

    $bullet-size: 4px;

    position: relative;
    background-color: $black;
    width: $bullet-size;
    height: $bullet-size;
    opacity: 1;

    // &::after{

    //     $pseudo-size: 32px;

    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate3d(-50%, -50%, 0) scale(0);
    //     width: $pseudo-size;
    //     height: $pseudo-size;
    //     border-radius: $pseudo-size;
    //     border: 1px solid $black;
    //     // opacity: 0;
    //     transition: transform .3s $easeSmooth;
    //     // transition: all .3s $easeSmooth;

    // }

    // &.swiper-pagination-bullet-active{

    //     &::after{

    //         // opacity: 1;
    //         transform: translate3d(-50%, -50%, 0) scale(1);

    //     }

    // }

}

.swiper-button-prev,
.swiper-button-next{

    z-index: 9;

    $btn-size: 32px;

    width: $btn-size;
    height: $btn-size;
    border-radius: $btn-size;
    border: 1px solid $black;

    transition: background-color .4s;

    &::after{

        content: '';
        position: absolute;
        inset: 0;
        left: -2px;

        margin: auto;

        width: 6px;
        height: 6px;

        border: {
            top: 1px solid $black;
            right: 1px solid $black;
        };

        transform: rotate(45deg);

        transition: border-color .4s;

    }

    &:hover {
        background-color: $black;

        &:after {
            border-color: $white;
        }
    }

}

.swiper-button-prev{

    &:after {
        left: 0;
        right: -2px;

        transform: rotate(225deg);
    }

}

.swiper-controls-inline {
    position: relative;

    display: flex;
    gap: 8px;

    > div {
        position: relative;
        inset: auto;

        margin: 0;

        transform: none;
    }
}