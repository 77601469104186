#page-products {
    .products_banner {
        position: relative;

        padding: 220px 0 80px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm){

            padding: 150px 0 80px;

        }

        &:before {
            content: '';

            position: absolute;
            inset: 0;
            z-index: 1;

            background-color: rgba($black, .7);
        }

        &-container {
            position: relative;
            z-index: 2;

            color: $white;
            text-align: center;
        }

        &-colors {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 24px;

            &-item {
                min-width: 40px;
                aspect-ratio: 1;

                border: 4px solid $light-grey;
                border-radius: 50%;

                transition: opacity .4s, border-color .4s;

                cursor: pointer;

                &.--muted {
                    opacity: .7;
                }

                &.--active {
                    border-color: $white;
                    opacity: 1;
                }
            }
        }

        &-styles {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;

            flex-wrap: wrap;

            &-item {
                &.--active {
                    color: $black;

                    background-color: $white;
                }
            }

            @include media-breakpoint-down(sm){

                gap: 16px;

            }

            &:hover{

                .btn-overlay{

                    @include media-breakpoint-down(sm){
    
                        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    
                    }

                }

            }

        }
    }

    .products-list {
        --per-row: 4;
        --col-gap: 32px;
        --row-gap: 40px;

        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        gap: var(--row-gap) var(--col-gap);

        &-header {
            width: 100%;
        }

        .product_card {
            --max: Max(0, calc(var(--per-row) - 1));

            width: calc((100% - var(--max) * var(--col-gap)) / var(--per-row));
        }

        @include media-breakpoint-down(lg) {
            --per-row: 3;
        }

        @include media-breakpoint-down(md) {
            --per-row: 2;
            --col-gap: 8px;
            --row-gap: 16px;
        }
    }
}