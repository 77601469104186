#footer{

    .footer-top{

        padding-top: 200px;
        padding-bottom: 208px;
        background-color: $black;
        color: $white;

        @include media-breakpoint-down(lg) {
            padding: 48px 0 24px;
        }

        .about{

            // max-width: 300px;
            flex: 0 0 302px;

            @include media-breakpoint-down(xl){

                flex: 1 0 302px;

            }

            .item-logo{

                display: block;
                max-width: 234px;

                img,
                svg{

                    width: 100%;
                    height: auto;

                }

            }

            .txt-left{

                margin-top: 35px;
                max-width: 302px;

            }

            .social-networks{
                font-family: $font-family-custom;
                text-transform: uppercase;

                margin-top: 35px;

                .rs-list{

                    margin-top: 24px;

                    display: flex;
                    align-items: center;

                    .rs-item{

                        &:not(:last-child){

                            margin-right: 12px;
                        }

                        .rs-link{

                            $icon-size: 12px;

                            display: block;
                            width: $icon-size;
                            height: $icon-size;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            filter: contrast(0) brightness(2);

                        }

                    }

                }

            }

        }

        .menus{

            flex: 0 0 404px;

            @include media-breakpoint-down(xl){

                flex: 0 1 404px;

            }

            @include media-breakpoint-down(md){

                margin-top: 50px;

            }

            .menu-footer{

                ul{

                    li{

                        &:not(:last-child){

                            margin-bottom: 24px;

                        }

                    }

                }

            }

        }

        .contact{

            padding: 32px 48px;
            background-color: $white;
            color: $black;
            // max-width: 403px;
            flex: 0 0 403px;

            @include media-breakpoint-down(xl){

                margin-top: 50px;

            }

            @include media-breakpoint-down(md){

                flex: 1 0 403px;

            }

            @include media-breakpoint-down(sm){

                flex: 1 1 403px;

            }

            .cms{

                margin-top: 24px;

            }

            .cta-contact{

                margin-top: 24px;

            }

        }

    }

    .footer-bottom{

        padding-top: 29px;
        padding-bottom: 27px;
        background-color: $black;
        font-size: 10px;
        color: $white;

        .copyright{

            @include media-breakpoint-down(lg){

                flex: 1 0 100%;

            }

            @include media-breakpoint-down(md){

                justify-content: center;

            }

        }

        .legals{

            @include media-breakpoint-down(lg){

                margin-top: 10px;
                flex: 1 0 100%;

            }

            @include media-breakpoint-down(md){

                justify-content: center;

            }

            ul{

                li{

                    position: relative;

                    button {
                        color: $white;
                        font-size: 10px;
                        font-weight: 300;

                        padding: 0;

                        border: none;
                        background-color: transparent;

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    &:not(:last-child){

                        margin-right: 40px;

                        &::after{

                            $bullet-size: 2px;

                            content: '';
                            position: absolute;
                            top: 7px;
                            right: -20px;
                            width: $bullet-size;
                            height: $bullet-size;
                            border-radius: $bullet-size;
                            background-color: $white;

                        }

                    }

                }

            }

        }

        .conception{

            text-transform: uppercase;

            @include media-breakpoint-down(lg){

                margin-top: 10px;
                flex: 1 0 100%;

            }

            @include media-breakpoint-down(md){

                justify-content: center;

            }

        }

    }

}