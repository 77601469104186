/*
|--------------------
|     VIDEO
|--------------------
*/
.flexible-video{

    .video-container{

        position: relative;

    }

    .poster{

        position: absolute;
        inset: 0;

    }

    .play-btn{

        $play-size: 48px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: $play-size;
        height: $play-size;
        border: 1px solid $black;
        border-radius: $play-size;
        background-color: $white;
        transition: background .3s $easeSmooth;
        cursor: pointer;

        &::after{

            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(calc(-50% + 1px), -50%, 0);
            width: 7px;
            height: 13px;
            background-color: $black;
            mask:
                url('../img/icon/play.svg')
                center / auto
                // center / $icon-width $icon-height
                no-repeat
            ;
            -webkit-mask:
                url('../img/icon/play.svg')
                center / auto
                // center / $icon-width $icon-height
                no-repeat
            ;

        }

        &:hover{

            border-color: $white;
            background-color: $black;

            &::after{

                background-color: $white;

            }

        }

    }

}