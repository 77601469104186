.text_image_1 {
    $el: &;

    position: relative;

    &:before {
        content: '';

        position: absolute;

        aspect-ratio: 10 / 13;
    }

    &-container {
        position: relative;
        z-index: 2;

        padding: {
            top: 160px;
        }
    }

    &-image {
        position: relative;

        aspect-ratio: 10 / 13;

        &-shadow {
            content: '';

            position: absolute;
            top: -160px;
            right: -180px;
            z-index: 1;

            width: 100%;
            height: 100%;

            background-color: $black;
        }

        &-item {
            position: absolute;
            inset: 0;
            z-index: 2;

            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &--right {
        #{$el}-image {
            &-shadow {
                left: -180px;
                right: auto;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        overflow: hidden;

        &-container {
            padding: {
                top: 0
            }
        }

        &-image {
            margin: {
                top: 32px
            }

            &-shadow {
                top: -32px;
                right: -32px;
            }
        }

        &--right {
            #{$el}-image {
                &-shadow {
                    left: -32px;
                    right: auto;
                }
            }
        }
    }
}