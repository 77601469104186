.flex-banner {
    --marginBottom: 120px;

    position: relative;

    aspect-ratio: 8 / 5;

    display: flex;
    align-items: center;

    margin: {
        bottom: var(--marginBottom)
    }
    padding: {
        top: 140px
    }

    background-color: $black;

    color: $white;

    &-image {
        position: absolute;
        top: 140px;
        right: 0;
        bottom: calc(var(--marginBottom) * -1);
        z-index: 1;

        width: 50%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &:after {
            content: '';

            position: absolute;
            inset: 0;

            background: linear-gradient(90deg, rgba($black, 0.75) 0%, rgba($black, 0) 100%);
        }
    }

    &-container {
        position: relative;
        z-index: 2;
    }

    @include media-breakpoint-down(lg) {
        --marginBottom: 80px;

        &-image {
            width: 40%;
        }
    }

    @include media-breakpoint-down(md) {
        --marginBottom: 0;

        &-image {
            display: none;
        }
    }
}