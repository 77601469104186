/*
|-------------------
| Ratio classes creation
|-------------------
| - Generated classes: .ratio-{size}
|
*/
$ratios: (
    'square':        '1/1',
    'almost-square': '1/.85',
    'landscape':     '1/.7',
    'landscape-sm':  '1/.6',
    'portrait':      '1/1.3',
    'showroom-home': '24/29',
    'product-home':  '81/113',
    'news':          '5/3',
    'team':          '35/45',
    'team-big':      '74/119',
    'video':         '16/9',
    'prlx-news':     '187/234'
);