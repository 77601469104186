/*
|--------------------
|   Title + Texte
|--------------------
*/
.flexible-title-txt{

    --pb: 80px;

    padding-block: var(--pb);

    @include media-breakpoint-down(md){

        --pb: 60px;

    }

    @include media-breakpoint-down(sm){

        --pb: 40px;

    }

}