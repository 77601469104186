.video {
    position: relative;

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;

        border: none;
    }

    &-cover {
        position: absolute;
        inset: 0;
        z-index: 1;

        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        cursor: pointer;

        &:before {
            content: '';

            position: absolute;
            inset: 0;

            background-color: rgba($black, .5);
        }
    }

    &-play {
        position: absolute;
        inset: 0 0 0 2px;
        z-index: 2;

        margin: auto;

        width: 48px;
        height: 48px;

        background-color: $white;
        border-radius: 50%;

        pointer-events: none;

        &:before {
            content: '';

            position: absolute;
            inset: 0;

            margin: auto;

            width: 0;
            height: 0;

            border-style: solid;
            border-width: 6px 0 6px 6px;
            border-color: transparent transparent transparent $black;
        }
    }
}